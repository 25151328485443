import { render, staticRenderFns } from "./IxConfig.vue?vue&type=template&id=667a2c71&scoped=true"
import script from "./IxConfig.vue?vue&type=script&lang=js"
export * from "./IxConfig.vue?vue&type=script&lang=js"
import style0 from "./IxConfig.vue?vue&type=style&index=0&id=667a2c71&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667a2c71",
  null
  
)

export default component.exports