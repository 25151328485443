<template>
  <div>
    <!-- Step Navigation Row -->
    <div v-loading="isLoadingPrices"
      element-loading-spinner="d-none"
      class="header"
      :class="{ 'sticky-top': !layout.integratedHeader }">
      <!-- Step Navigation Row Title -->
      <h3 class="text-align-center">
        {{ componentTitle }}
      </h3>

      <!-- Step Navigation Row Buttons -->
      <div class="flex-row-centered steps wl-steps">
        <!-- Step Navigation Row Back Button -->
        <div class="m-0-5 width-120px">
          <el-button v-if="stepIndex > 0"
            type="primary"
            @click="goToLastStep">
            <i class="fas fa-arrow-circle-left"
              aria-hidden="true" /> {{ $t('general.back') }}
          </el-button>
        </div>

        <!-- Step Navigation Row Step Buttons -->
        <div class="flex-1">
          <el-steps :active="stepIndex"
            data-testid="steps"
            align-center
            class="m-auto max-width-700px mt-1-3">
            <el-step v-for="(step, index) in allSteps"
              :key="index"
              :title="step.title"
              :class="calcStepStatus(step, index) === 'wait' ? '' : 'cursor-pointer'"
              :status="calcStepStatus(step, index)"
              :data-step-name="step.title"
              @click.native="event => handleStepClick(event, index)" />
          </el-steps>
        </div>

        <!-- Step Navigation Row Next Button -->
        <div class="text-align-right m-0-5 width-120px">
          <el-button v-if="!finalStep"
            type="primary"
            data-name="next-create-connection"
            class="step-button"
            :plain="!canNextStep"
            @click="goToNextStep">
            {{ $t('general.next') }}
            <i class="fas fa-arrow-circle-right"
              aria-hidden="true" />
          </el-button>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="p-20px pb-50px">
      <!-- Connection Detail Cards -->
      <div class="flex-column flex-align-center">
        <connection-details-cards :left-active="currentStep.id === 'configure-aend'"
          :left-detail-lines="aEndDetailLines"
          :left-diversity-zone="aEndDiversityZone"
          :left-left-icon-id="iconType.aEnd"
          :right-active="currentStep.id === 'configure-bend'"
          :right-disabled="rightDetailCardDisabled"
          :right-left-icon-id="iconType.bEnd"
          :right-detail-lines="bEndDetailLines"
          :right-diversity-zone="bEndDiversityZone" />
      </div>

      <!-- Pricebox -->
      <div v-if="showPriceBox"
        class="price-box">
        <span v-if="priceStore">
          <strong>{{ $t('pricebook.monthly-rate') }}: </strong>
          <span data-name="monthlyRate">{{ priceStore.monthlyRate | formatInCurrency(priceStore.currency) }}</span>
          ({{ $t('pricebook.excludes-tax') }})
        </span>
      </div>

      <!-- Step-Driven Content -->
      <div v-loading="isLoadingPrices"
        class="content-panel">
        <el-form ref="createConnectionForm"
          :model="createConnectionForm"
          :rules="createConnectionRules"
          :validate-on-rule-change="false">
          <!-- STEP: Select Type -->
          <section v-if="currentStep.id === 'select-type'">
            <h4 class="text-align-center mt-2 mb-1">
              {{ $t('connections.choose-type') }}
            </h4>

            <el-form-item prop="destinationType">
              <div class="d-flex flex-justify-center flex-wrap my-2 mx-auto">
                <!-- Cloud Connection Type -->
                <el-tooltip v-if="vxcConnectionPermitted"
                  placement="top"
                  :content="$t('connections.csp')"
                  :open-delay="500">
                  <el-button class="dest-type"
                    :class="displayAsActiveWhenDestinationTypeIs('cloud')"
                    data-name="cloud"
                    @click="typeSelected('cloud')">
                    <div class="dest-type-content">
                      <mu-mega-icon icon="cloud" />
                      <div class="dest-type-text">
                        {{ $t('connections.cloud') }}
                      </div>
                    </div>
                  </el-button>
                </el-tooltip>

                <!-- Private VXC Connection Type -->
                <el-tooltip v-if="vxcConnectionPermitted"
                  placement="top"
                  :content="privateVxcTargetAvailable ? $t('connections.private') : $t('connections.private-vxc-no-valid-target')"
                  :open-delay="500">
                  <el-button class="dest-type"
                    :class="displayAsActiveWhenDestinationTypeIs('private')"
                    :disabled="!privateVxcTargetAvailable"
                    data-name="privateVxc"
                    @click="typeSelected('private')">
                    <div class="dest-type-content">
                      <mu-mega-icon icon="PrivateVXC" />
                      <div class="dest-type-text">
                        {{ $t('connections.private-vxc') }}
                      </div>
                    </div>
                  </el-button>
                </el-tooltip>

                <!-- Megaport Internet Connection Type -->
                <div v-if="transitVxcConnectionPermitted"
                  class="d-flex flex-column flex-align-center">
                  <el-tooltip placement="top"
                    :content="$t('connections.transit')"
                    :open-delay="500">
                    <el-button class="dest-type"
                      :class="displayAsActiveWhenDestinationTypeIs('transit')"
                      data-name="transitVxc"
                      @click="typeSelected('transit')">
                      <div class="dest-type-content">
                        <mu-mega-icon icon="MegaportInternet" />
                        <div class="dest-type-text">
                          {{ $t('productNames.transitVxc') }}
                        </div>
                      </div>
                    </el-button>
                  </el-tooltip>
                </div>

                <!-- IX Connection Type -->
                <el-tooltip v-if="ixConnectionPermitted"
                  placement="top"
                  :content="$t('connections.ix')"
                  :open-delay="500">
                  <el-button class="dest-type"
                    :class="displayAsActiveWhenDestinationTypeIs('ix')"
                    data-name="ix"
                    @click="typeSelected('ix')">
                    <div class="dest-type-content">
                      <mu-mega-icon icon="IX" />
                      <div class="dest-type-text">
                        {{ $t('connections.internet-exchange') }}
                      </div>
                    </div>
                  </el-button>
                </el-tooltip>

                <!-- Megaport Marketplace Connection Type -->
                <el-tooltip v-if="marketplaceConnectionPermitted"
                  placement="top"
                  :content="$t('connections.marketplace')"
                  :open-delay="500">
                  <el-button class="dest-type"
                    :class="displayAsActiveWhenDestinationTypeIs('mx')"
                    data-name="megaportExchange"
                    @click="typeSelected('mx')">
                    <div class="dest-type-content">
                      <mu-mega-icon icon="Marketplace" />
                      <div class="dest-type-text">
                        {{ $t('productNames.marketplace') }}
                      </div>
                    </div>
                  </el-button>
                </el-tooltip>

                <!-- Service Key Connection Type -->
                <el-tooltip v-if="vxcConnectionPermitted"
                  placement="top"
                  :content="$t('connections.service-key')"
                  :open-delay="500">
                  <el-button class="dest-type"
                    :class="displayAsActiveWhenDestinationTypeIs('key')"
                    data-name="serviceKey"
                    @click="typeSelected('key')">
                    <div class="dest-type-content">
                      <mu-mega-icon icon="ServiceKey" />
                      <div class="dest-type-text">
                        {{ $t('connections.enter-service-key') }}
                      </div>
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
            </el-form-item>
          </section>

          <!-- STEP: Select Port -->
          <section v-if="currentStep.id === 'select-port'">
            <target-service-key v-if="destinationTypeIsServiceKey"
              :a-end="createConnectionForm.serviceObj.aEnd"
              :value="createConnectionForm.serviceObj.bEnd"
              @input="bEndChosen" />
            <target-select-ix v-else-if="destinationTypeIsIx"
              ref="targetSelectIx"
              v-model="createConnectionForm.serviceObj.bEnd.ixType"
              :a-end-loc="aEndPort.locationId" />
            <target-select v-else
              :destination-type="createConnectionForm.destinationType"
              :a-end="createConnectionForm.serviceObj.aEnd"
              :company-selected-uid.sync="createConnectionForm.companySelectedUid"
              :b-end="createConnectionForm.serviceObj.bEnd"
              :azure-show-all-ports.sync="createConnectionForm.azureShowAllPorts"
              @update:bEnd="bEndChosen" />
          </section>

          <!-- STEP: Connection Details -->
          <section v-if="currentStep.id === 'configure-connection'"
            class="configure-connection-container">
            <h4 class="mt-1 mb-4 text-align-center">
              {{ $t('connections.connection-details') }}
            </h4>

            <!-- Connection Name -->
            <el-form-item prop="serviceObj.productName"
              :label="$t('connections.connection-name')"
              label-width="250px">
              <el-input v-model="createConnectionForm.serviceObj.productName"
                data-testid="connection-name-input"
                :placeholder="$t('connections.connection-name')"
                :data-demo="destinationTypeIsIx ? 'Example IX' : 'Example VXC'"
                autocomplete="off"
                data-name="nameConnection" />
            </el-form-item>

            <!-- Partner Vantage Deals -->
            <el-form-item v-if="showDeals"
              prop="serviceObj.dealUid"
              :label="$t('general.partner-deals')"
              label-width="250px">
              <el-select v-model="createConnectionForm.serviceObj.dealUid"
                class="min-width-350px"
                data-testid="partner-deal-select">
                <el-option v-for="deal in deals"
                  :key="deal.entityUid"
                  :label="deal.dealId ? `${deal.opportunityName} (${deal.dealId})` : deal.opportunityName"
                  :value="deal.entityUid" />
              </el-select>
            </el-form-item>

            <!-- Invoice Ref -->
            <el-form-item prop="serviceObj.costCentre"
              label-width="250px">
              <template #label>
                {{ $t('services.invoice-reference') }}
                <el-tooltip placement="top"
                  :open-delay="500">
                  <template #content>
                    <p class="tooltip-p">
                      {{ $t('services.invoice-reference-explanation') }}
                    </p>
                    <p v-if="!disabledFeatures.knowledgeBase"
                      class="tooltip-p">
                      {{ $t('general.details-help') }}
                      <a href="https://docs.megaport.com/connections/"
                        target="_blank"
                        rel="noopener">
                        <el-button size="mini">{{ $t('general.documentation') }}</el-button>
                      </a>
                    </p>
                  </template>
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-input v-model="createConnectionForm.serviceObj.costCentre"
                :placeholder="$t('services.invoice-reference')"
                name="costCentre" />
            </el-form-item>

            <!-- Rate Limit -->
            <input-ratelimit ref="rateLimitInput"
              v-model="createConnectionForm.serviceObj.rateLimit"
              prop="serviceObj.rateLimit"
              label-width="250px"
              data-testid="connection-rate-limit"
              :label="$t('services.rate-limit')"
              :help-text="destinationTypeIsIx ? $t('connections.ix-rate-limit-help') : $t('connections.vxc-rate-limit-help')"
              :min-rate-limit="minRateLimit"
              :higher-speed-url="higherSpeedUrl"
              :max-rate-limit="maxRateLimit"
              :fixed-bandwidths="fixedBandwidths"
              :fixed-speed-service="showRateLimitWarning"
              :fixed-rate="fixedRate" />

            <!-- VXC/IX State -->
            <el-form-item prop="shutdown"
              label-width="250px">
              <template #label>
                <div class="d-flex flex-align-center flex-justify-end">
                  <new-badge class="mr-0-5" />
                  {{ $t('services.type-state', { type: $t(destinationTypeIsIx ? 'productNames.ix' : 'productNames.vxc') }) }}
                  <el-tooltip placement="top"
                    :content="$t('tooltips.shutdown-state')"
                    :open-delay="500">
                    <i class="fas fa-question-circle color-info popover-info-icon"
                      aria-hidden="true" />
                  </el-tooltip>
                </div>
              </template>

              <el-radio-group v-model="createConnectionForm.serviceObj.shutdown"
                name="shutdown">
                <el-radio-button :label="false"
                  class="inverse-padding">
                  {{ $t('general.enabled') }}
                </el-radio-button>
                <el-radio-button :label="true"
                  class="inverse-padding">
                  {{ $t('general.shut-down') }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <!-- Confirm Shutdown Dialog -->
            <simple-confirmation-dialog :visible="showShutdownModal"
              @cancel="createConnectionForm.serviceObj.shutdown = false"
              @confirm="showShutdownModal = false">
              <template #title>
                <h3>{{ $t('services.shutdown-dialog-title') }}</h3>
              </template>
              <p class="mt-0">
                {{ $t('services.shutdown-dialog-message') }}
              </p>
              <strong>{{ $t('services.shutdown-dialog-note') }}</strong>
            </simple-confirmation-dialog>

            <!-- A-End vNIC -->
            <el-form-item v-if="showAEndVNics"
              prop="serviceObj.aEnd.vNicIndex"
              label-width="250px">
              <template #label>
                {{ determineVNicLabel('A') }}
                <el-tooltip placement="top"
                  :content="$t('connections.a-end-vnic-help')"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-select v-model="createConnectionForm.serviceObj.aEnd.vNicIndex"
                class="min-width-350px"
                data-testid="vnic-select"
                @change="handleVNicChangeForEnd('a')">
                <el-option v-for="(vnic, index) in aEndVNics"
                  :key="`vNIC-${index}`"
                  :label="`vNIC-${index} ${vnic.description || ''}`"
                  :value="index" />
              </el-select>
            </el-form-item>

            <!-- A-End "Preferred" Inner VLAN -->
            <input-vlan v-if="aEndIsMve && showAEndVlan"
              ref="serviceObj.aEnd.innerVlan"
              prop="serviceObj.aEnd.innerVlan"
              :value="aEndInnerVlan"
              :service="createConnectionForm.serviceObj"
              end="a"
              label-width="250px"
              data-name="aEnd_innerVlan"
              data-testid="a-end-inner-vlan"
              @input="aInnerVlanEdited" />

            <!-- A-End Single VLAN -->
            <input-vlan v-else-if="showAEndVlan"
              ref="serviceObj.aEnd.vlan"
              v-model="createConnectionForm.serviceObj.aEnd.vlan"
              prop="serviceObj.aEnd.vlan"
              :service="createConnectionForm.serviceObj"
              end="a"
              label-width="250px"
              data-name="aEnd_Vlan"
              data-testid="a-end-vlan" />

            <div v-if="showVLANWarning"
              class="ml-250px">
              {{ $t('connections.vlan-fixed') }}
            </div>

            <p v-if="isGoogleConnection && !createConnectionForm.serviceObj.bEnd.partnerConfig.pairingKey"
              class="error-message">
              {{ $t('connections.missing-google-pairing-key') }}
            </p>

            <!-- B-End vNIC -->
            <el-form-item v-if="showBEndVNics"
              prop="serviceObj.bEnd.vNicIndex"
              label-width="250px">
              <template #label>
                {{ determineVNicLabel('B') }}
                <el-tooltip placement="top"
                  :content="$t('connections.b-end-vnic-help')"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-select v-model="createConnectionForm.serviceObj.bEnd.vNicIndex"
                class="min-width-350px"
                data-testid="vnic-select"
                @change="handleVNicChangeForEnd('b')">
                <el-option v-for="(vnic, index) in bEndVNics"
                  :key="`vNIC-${index}`"
                  :label="`vNIC-${index} ${vnic.description || ''}`"
                  :value="index" />
              </el-select>
            </el-form-item>

            <!-- VLAN B-End "Preferred" Inner VLAN -->
            <input-vlan v-if="bEndIsMve && showBEndVlan"
              ref="serviceObj.bEnd.innerVlan"
              prop="serviceObj.bEnd.innerVlan"
              :value="bEndInnerVlan"
              :service="createConnectionForm.serviceObj"
              end="b"
              label-width="250px"
              data-name="bEnd_innerVlan"
              data-testid="b-end-inner-vlan"
              @input="bInnerVlanEdited" />

            <!-- VLAN B-End Single VLAN -->
            <input-vlan v-else-if="showBEndVlan"
              ref="serviceObj.bEnd.vlan"
              v-model="createConnectionForm.serviceObj.bEnd.vlan"
              prop="serviceObj.bEnd.vlan"
              :service="createConnectionForm.serviceObj"
              end="b"
              label-width="250px"
              data-name="bEnd_Vlan"
              data-testid="b-end-vlan" />

            <!-- Minimum Term -->
            <!-- Remove v-if directive when term functionality has been extended to IXs -->
            <term-select v-if="!destinationTypeIsIx"
              v-model="createConnectionForm.serviceObj.term"
              :term-prices="termPrices"
              prop="serviceObj.term"
              data-testid="connection-term">
              <!-- Minimum Term Disclaimer -->
              <el-collapse-transition>
                <el-alert v-if="createConnectionForm.serviceObj.term > 1"
                  :title="$t('general.important-information')"
                  type="warning"
                  :closable="false"
                  class="mt-1-4 py-1-5 line-height-initial">
                  <p class="color-warning">
                    {{ $t('connections.minimum-term-disclaimer') }}
                  </p>
                  <p class="color-warning">
                    {{ $t('connections.termed-delete-etf') }}
                  </p>
                  <p class="color-warning">
                    {{ $t('connections.avoid-etf') }}
                  </p>
                </el-alert>
              </el-collapse-transition>
            </term-select>

            <!-- Azure Connection Configuration -->
            <template v-if="isAzureConnection">
              <!-- Missing Service Key Error Message -->
              <p v-if="!createConnectionForm.serviceObj.bEnd.partnerConfig.serviceKey"
                class="error-message">
                {{ $t('connections.missing-azure-service-key') }}
              </p>

              <!-- Azure Peering VLAN -->
              <section v-else-if="aEndIsPort || aEndIsMve"
                class="azure-settings-group">
                <!-- Heading -->
                <h5 class="text-align-center mt-1 mb-2">
                  {{ $t('connections.azure-vlan') }}
                </h5>

                <!-- Configure single Azure peering VLAN -->
                <el-form-item prop="usingAzurePeering"
                  label-width="375px"
                  :label="$t('connections.configure-azure-vlan')">
                  <el-switch :value="usingAzurePeering"
                    data-name="usingAzurePeering"
                    @change="usingAzurePeeringChanged" />
                  <!-- Documentation Link -->
                  <a href="https://docs.megaport.com/cloud/megaport/microsoft/"
                    target="_blank"
                    rel="noopener"
                    class="ml-1">{{ $t('general.documentation') }}</a>
                </el-form-item>

                <!-- Azure Peering VLAN -->
                <el-form-item ref="innerVlan"
                  prop="serviceObj.bEnd.innerVlan"
                  label-width="375px"
                  :label="$t('connections.azure-vlan')">
                  <el-input :value="bEndInnerVlan"
                    :disabled="!usingAzurePeering"
                    :placeholder="$t('connections.enter-vlan')"
                    data-name="innerVlan"
                    data-demo="42"
                    @input="bInnerVlanEdited" />
                </el-form-item>
              </section>
            </template>
          </section>

          <!-- STEP: A-End Configuration -->
          <template v-if="currentStep.id === 'configure-aend'">
            <!-- Do NOT make the same mistake as me and remove the key on this! Thanks to some
              primo spaghetti code the key is required to stop aEnd state spilling into the bEnd -->
            <mcr-config v-if="aEndIsMcr"
              key="aEnd"
              end="A"
              :config="createConnectionForm.serviceObj.aEnd"
              :service-obj="createConnectionForm.serviceObj"
              :mcr-port="aEndPort"
              @update="updateMcrConfig($event, 'aEnd')" />
          </template>

          <!-- STEP: B-End IX Configuration -->
          <template v-if="currentStep.id === 'configure-ix'">
            <ix-config v-if="destinationTypeIsIx"
              ref="ixConfig"
              v-model="createConnectionForm.serviceObj" />
          </template>

          <!-- STEP: B-End Cloud Configuration -->
          <template v-if="currentStep.id === 'configure-bend' && !destinationTypeIsServiceKey && !isSapWasabiConnection">
            <ix-config v-if="destinationTypeIsIx"
              v-model="createConnectionForm.serviceObj" />

            <!-- Do NOT make the same mistake as me and remove the key on this! Thanks to some
              primo spaghetti code the key is required to stop aEnd state spilling into the bEnd -->
            <mcr-config v-else-if="bEndIsMcr"
              key="bEnd"
              end="B"
              :config="createConnectionForm.serviceObj.bEnd"
              :service-obj="createConnectionForm.serviceObj"
              :mcr-port="bEndPort"
              @update="updateMcrConfig($event, 'bEnd')" />

            <aws-config v-else-if="bEndPort.connectType === 'AWS' || bEndPort.connectType === 'AWSHC'"
              v-model="createConnectionForm.serviceObj.bEnd"
              :a-end-connection="aEndPort"
              :is-mcr="aEndIsMcr"
              :connect-type="bEndPort.connectType"
              :connection-name="createConnectionForm.serviceObj.productName" />

            <alibaba-config v-else-if="bEndPort.connectType === 'ALIBABA'"
              v-model="createConnectionForm.serviceObj.bEnd" />

            <amsix-config v-else-if="bEndPort.connectType === 'AMSIX'"
              v-model="createConnectionForm.serviceObj.bEnd"
              :source="aEndPort" />

            <bgp-config v-else-if="showBgpConfig"
              v-model="createConnectionForm.serviceObj.bEnd"
              :a-end-connection="aEndPort"
              :is-mcr="aEndIsMcr"
              :connect-type="bEndPort.connectType" />

            <outscale-config v-else-if="bEndPort.connectType === 'OUTSCALE'"
              v-model="createConnectionForm.serviceObj.bEnd"
              :a-end-connection="aEndPort" />

            <ibm-config v-else-if="bEndPort.connectType === 'IBM'"
              v-model="createConnectionForm.serviceObj.bEnd"
              :a-end-connection="aEndPort" />
          </template>

          <!-- STEP: Summary -->
          <section v-if="currentStep.id === 'final'"
            v-loading="saving"
            :element-loading-text="$t('general.saving')"
            data-testid="summary-table"
            class="summary-table">
            <h4 class="text-align-center mt-1 mb-3">
              {{ $t('general.summary') }}
            </h4>
            <!-- Common Fields -->
            <!-- Connection Name -->
            <simple-read-only-field :label="$t('connections.connection-name')"
              :value="createConnectionForm.serviceObj.productName"
              data-testid="connection-name" />

            <!-- Service Level Reference (Cost Centre) -->
            <simple-read-only-field v-if="createConnectionForm.serviceObj.costCentre"
              :label="$t('services.invoice-reference')"
              :value="createConnectionForm.serviceObj.costCentre"
              data-testid="cost-centre" />

            <!-- Rate Limit -->
            <simple-read-only-field :label="$t('services.rate-limit')"
              :value="speedFix(createConnectionForm.serviceObj.rateLimit)"
              data-testid="rate-limit" />

            <!-- VXC/IX State -->
            <simple-read-only-field :label="$t('services.type-state', { type: $t(destinationTypeIsIx ? 'productNames.ix' : 'productNames.vxc') })"
              :value="$t(createConnectionForm.serviceObj.shutdown ? 'general.shut-down' : 'general.enabled')" />

            <!-- Term / Subscription -->
            <!-- Remove v-if directive when term functionality has been extended to IXs -->
            <simple-read-only-field v-if="!destinationTypeIsIx"
              :label="$t(isPartnerVantage ? 'partner-vantage.subscription' : 'general.term')"
              :value="minimumTermLabel(createConnectionForm.serviceObj.term)"
              data-testid="term" />

            <!-- IX-Specific Fields -->
            <template v-if="destinationTypeIsIx">
              <!-- Preferred VLAN -->
              <simple-read-only-field :label="$t('connections.preferred-vlan')"
                :value="determineVlanSummaryDisplayValue(createConnectionForm.serviceObj.vlan)"
                data-testid="ix-vlan" />

              <!-- ASN -->
              <simple-read-only-field :label="$t('connections.asn')"
                :value="createConnectionForm.serviceObj.asn"
                data-testid="ix-asn" />

              <!-- MAC Address -->
              <simple-read-only-field :label="$t('connections.mac-address')"
                :value="createConnectionForm.serviceObj.macAddress"
                data-testid="ix-mac-address" />

              <!-- Peer Macro -->
              <simple-read-only-field v-if="createConnectionForm.serviceObj.bEnd.ixType.ecix && createConnectionForm.serviceObj.ixPeerMacro"
                :label="$t('connections.peer-macro')"
                :value="createConnectionForm.serviceObj.ixPeerMacro"
                data-testid="ix-peer-macro" />

              <!-- Graph Visibility -->
              <simple-read-only-field :label="$t('connections.graph-visibility')"
                :value="createConnectionForm.serviceObj.publicGraph ? $t('general.public') : $t('general.private')"
                data-testid="ix-graph-visibility" />
            </template>

            <!-- VXC-Specific Fields -->
            <template v-else>
              <!-- A-End vNIC -->
              <simple-read-only-field v-if="aEndIsMve"
                :label="determineVNicLabel('A')"
                :value="`vNIC-${aEndVNicIndex} ${aEndVNics[aEndVNicIndex].description}`"
                data-testid="a-end-vnic" />

              <!-- Preferred A-End VLAN -->
              <simple-read-only-field v-if="aEndIsMve && showAEndVlan"
                :label="$t('connections.preferred-end-vlan', { title: $t('general.x-end', { end: 'A' }) })"
                :value="determineVlanSummaryDisplayValue(aEndInnerVlan)"
                data-testid="mve-a-end-vlan" />

              <!-- Preferred A-End VLAN -->
              <simple-read-only-field v-else-if="showAEndVlan"
                :label="$t('connections.preferred-end-vlan', { title: $t('general.x-end', { end: 'A' }) })"
                :value="determineVlanSummaryDisplayValue(aEndVlan)"
                data-testid="a-end-vlan" />

              <!-- B-End vNIC -->
              <simple-read-only-field v-if="bEndIsMve && !destinationTypeIsServiceKey"
                :label="determineVNicLabel('B')"
                :value="`vNIC-${bEndVNicIndex} ${bEndVNics[bEndVNicIndex].description}`"
                data-testid="b-end-vnic" />

              <!-- Azure peering (B-End) VLAN -->
              <simple-read-only-field v-if="isAzureConnection && bEndInnerVlan"
                :label="$t('connections.azure-vlan')"
                :value="bEndInnerVlan"
                data-testid="azure-vlan" />

              <!-- Preferred B-End VLAN -->
              <simple-read-only-field v-else-if="bEndIsMve && showBEndVlan"
                :label="$t('connections.preferred-end-vlan', { title: $t('general.x-end', { end: 'B' }) })"
                :value="determineVlanSummaryDisplayValue(bEndInnerVlan)"
                data-testid="mve-b-end-vlan" />

              <!-- Preferred B-End VLAN -->
              <simple-read-only-field v-else-if="showBEndVlan"
                :label="$t('connections.preferred-end-vlan', { title: $t('general.x-end', { end: 'B' }) })"
                :value="determineVlanSummaryDisplayValue(bEndVlan)"
                data-testid="b-end-vlan" />

              <!-- MCR A-End Connection -->
              <mcr-summary v-if="aEndIsMcr"
                :mcr-interfaces="aEndMcrInterfaces"
                end="A"
                :auto-configured="autoConfigured"
                :bgp-default-state-shutdown="bgpDefaultStateShutdown" />

              <!-- MCR B-End Connection -->
              <mcr-summary v-if="bEndIsMcr && !destinationTypeIsServiceKey"
                :mcr-interfaces="bEndMcrInterfaces"
                end="B" />

              <!-- Service Key -->
              <template v-if="destinationTypeIsServiceKey || isSapWasabiConnection">
                <!-- Service Key -->
                <simple-read-only-field :label="$t('services.service-key')"
                  :value="createConnectionForm.serviceObj.bEnd.partnerConfig.serviceKey.key"
                  data-testid="service-key" />

                <!-- B-End VLAN (from Service Key) -->
                <simple-read-only-field v-if="!showBEndVlan && createConnectionForm.serviceObj.bEnd.vlan"
                  :label="$t('connections.b-vlan-service-key')"
                  :value="createConnectionForm.serviceObj.bEnd.vlan"
                  data-testid="service-key-vlan" />

                <!-- Max Rate Limit -->
                <simple-read-only-field v-if="createConnectionForm.serviceObj.bEnd.partnerConfig.maxRateLimit"
                  :label="$t('services.max-rate-limit')"
                  :value="createConnectionForm.serviceObj.bEnd.partnerConfig.maxRateLimit"
                  data-testid="service-key-max-rate-limit" />
              </template>

              <!-- Cloud -->
              <template v-else-if="bEndCloudSummary">
                <!-- Cloud Details (AWS, etc.) Collapsible -->
                <el-tooltip placement="top"
                  :content="summaryShow.cloud ? $t('connections.hide-cloud-details') : $t('connections.show-cloud-details')"
                  :open-delay="500">
                  <div class="summary-section-head"
                    data-testid="show-cloud-details"
                    @click="summaryShow.cloud = !summaryShow.cloud">
                    <!-- For Megaport Internet (transit) connections, the B-End config details are shown here -->
                    <template v-if="destinationTypeIsTransit">
                      {{ $t('general.type-configuration', { product: $t('general.x-end', { end: 'B' }) }) }}
                      <em>({{ $t('productNames.transitVxc') }})</em>
                    </template>
                    <template v-else>
                      {{ $t('connections.cloud-details') }}
                      <em>({{ createConnectionForm.serviceObj.bEnd.partnerConfig.connectType }})</em>
                    </template>
                    <i class="fas fa-chevron-up action expand-arrow ml-0-5"
                      :class="!summaryShow.cloud ? 'active' : ''"
                      aria-hidden="true" />
                  </div>
                </el-tooltip>

                <!-- Cloud Details (AWS, etc.) Collapsible Content -->
                <el-collapse-transition>
                  <div v-if="summaryShow.cloud">
                    <simple-read-only-field v-for="line in bEndCloudSummary"
                      :key="line.key"
                      :label="line.key | capitalizeString"
                      :value="line.value"
                      :data-testid="`cloud-${kebabCase(line.key)}`" />
                  </div>
                </el-collapse-transition>
              </template>
            </template>

            <!-- Pricing -->
            <simple-read-only-field v-if="showPricing"
              :label="$t('pricebook.monthly-rate')"
              class="total"
              data-testid="pricing">
              <template v-if="priceStore">
                <span>{{ priceStore.monthlyRate | formatInCurrency(priceStore.currency) }}</span>
                <p class="m-0 fs-0-8em">
                  {{ $t('pricebook.excludes-tax') }}
                </p>
              </template>
              <template v-else>
                <span>{{ $t('pricebook.contact-sales') }}</span>
              </template>
            </simple-read-only-field>
          </section>
        </el-form>

        <!-- Action/Navigation Buttons -->
        <div class="text-align-right mt-20px">
          <!-- Cancel Button -->
          <el-button data-name="cancel-create-connection"
            @click="$router.push(resolveServicesPage())">
            {{ $t('general.cancel') }}
          </el-button>
          <!-- Back Button -->
          <el-button v-if="stepIndex > 0"
            type="primary"
            data-name="previous-create-connection"
            @click="goToLastStep">
            <i class="fas fa-arrow-circle-left"
              aria-hidden="true" /> {{ $t('general.back') }}
          </el-button>
          <!-- Add/Update Connection Button -->
          <el-button v-if="finalStep"
            data-testid="add-vxc-button"
            data-name="next-create-connection"
            type="primary"
            @click="goToNextStep">
            {{ addConnectionButtonContent }}
            <i class="fas fa-check-circle"
              aria-hidden="true" />
          </el-button>
          <!-- Next Button -->
          <el-button v-else
            type="primary"
            class="step-button"
            data-testid="next-button"
            data-name="next-create-connection"
            :plain="!canNextStep"
            @click="goToNextStep">
            {{ $t('general.next') }}
            <i class="fas fa-arrow-circle-right"
              aria-hidden="true" />
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// External tools
import Vue from 'vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { kebabCase, omit } from 'lodash'
import { v1 as uuid } from 'uuid'
import sdk from '@megaport/api-sdk'
// Internal tools
import captureSentryError from '@/utils/CaptureSentryError.js'
import { validateAsnDefault, validateAwsAsn, validateBGPPassword, validateIxAsn, validateOutscaleAsn, validateIxPeerMacro, validateIbmAsn, IP_CIDR_REGEX } from '@/validators.js'
import { convertSpeed, maxVXCSpeed, minVXCSpeed, maxIXSpeed, moneyFilter } from '@/helpers.js'
import { macAddressValidationRule } from '@/components/ui-components/MacAddress.vue'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
import { scopedPriceBook } from '@/utils/priceBook.js'
import { getSurveyLink } from '@/utils/surveys.js'
// Components
import TargetSelectComponent from '@/components/connection-extras/TargetSelect.vue'
import TargetSelectIxComponent from '@/components/connection-extras/TargetSelectIx.vue'
import TargetServiceKeyComponent from '@/components/connection-extras/TargetServiceKey.vue'
import AwsConfigComponent from '@/components/connection-extras/AwsConfig.vue'
import AlibabaConfigComponent from '@/components/connection-extras/AlibabaConfig.vue'
import AmsixConfigComponent from '@/components/connection-extras/AmsIxConfig.vue'
import BgpConfigComponent from '@/components/connection-extras/BgpConfig.vue'
import OutscaleConfigComponent from '@/components/connection-extras/OutscaleConfig.vue'
import IbmConfigComponent from '@/components/connection-extras/IbmConfig.vue'
import McrConfigComponent from '@/components/connection-extras/McrConfig.vue'
import IxConfigComponent from '@/components/connection-extras/IxConfig.vue'
import InputVlanComponent from '@/components/InputVlan.vue'
import InputRatelimitComponent from '@/components/InputRatelimit.vue'
import ConnectionValidator from '@/components/ConnectionValidations.js'
import ConnectionDetailsCards from '@/components/ui-components/ConnectionDetailsCards.vue'
import NewBadge from '@/components/ui-components/NewBadge.vue'
import SimpleReadOnlyField from '@/components/ui-components/SimpleReadOnlyField.vue'
import McrSummary from '@/components/connection-extras/McrSummary.vue'
import SimpleConfirmationDialog from '@/components/ui-components/SimpleConfirmationDialog.vue'
import TermSelect from '@/components/ui-components/TermSelect.vue'
// Integrations
import { captureEvent } from '@/utils/analyticUtils'
// Globals
import {
  CLOUD_ITEMS,
  SAP_DETAILS,
  WASABI_DETAILS,
  G_PRODUCT_TYPE_VXC,
  G_PRODUCT_TYPE_IX,
  G_PROVISIONING_DESIGN,
  DEFAULT_TERM_PRICES,
} from '@/Globals.js'

const MIN_AZURE_VLAN = 2
const MAX_AZURE_VLAN = 4093

export default {
  name: 'CreateConnection',

  components: {
    'target-select': TargetSelectComponent,
    'target-select-ix': TargetSelectIxComponent,
    'target-service-key': TargetServiceKeyComponent,
    'aws-config': AwsConfigComponent,
    'alibaba-config': AlibabaConfigComponent,
    'amsix-config': AmsixConfigComponent,
    'bgp-config': BgpConfigComponent,
    'outscale-config': OutscaleConfigComponent,
    'ibm-config': IbmConfigComponent,
    'mcr-config': McrConfigComponent,
    'ix-config': IxConfigComponent,
    'input-vlan': InputVlanComponent,
    'input-ratelimit': InputRatelimitComponent,
    'connection-details-cards': ConnectionDetailsCards,
    'new-badge': NewBadge,
    'simple-read-only-field': SimpleReadOnlyField,
    'mcr-summary': McrSummary,
    'simple-confirmation-dialog': SimpleConfirmationDialog,
    'term-select': TermSelect,
  },

  filters: {
    formatInCurrency: moneyFilter,
  },

  inject: ['layout', 'disabledFeatures', 'isFeatureEnabled'],

  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      // Dynamically set the page title for the CreateConnection.vue component based on product status.
      // This has to be done here because a productUid is always included in the URL params.
      const existingService = vm.$store.getters['Services/myConnections'].find(s => s.productUid === vm.$route.params.productUid)

      document.title = `${vm.$t('companyInfo.portal')} - ${vm.$t(existingService ? 'page-titles.edit-connection' : 'page-titles.create-connection')}`
    })
  },

  data() {
    const newService = { aEnd: {}, bEnd: {} }
    const existingService = this.$store.getters['Services/myConnections'].find(s => s.productUid === this.$route.params.productUid)
    const service = existingService || newService

    let destinationType = null

    if (service.productType === G_PRODUCT_TYPE_VXC) {
      if (service.bEnd.partnerConfig?.serviceKey) {
        destinationType = 'key'
      } else if (service.aEnd.ownerUid === service.bEnd.ownerUid) {
        destinationType = 'private'
      } else if (service.bEnd.partnerConfig?.connectType === 'TRANSIT') {
        destinationType = 'transit'
      } else {
        destinationType = 'mx'
      }

      // Real cloud services
      const realCloudServices = ['ALIBABA', 'AMSIX', 'AWS', 'AWSHC', 'AZURE', 'GCI', 'GOOGLE', 'IBM', 'NUTANIX', 'WASABI', 'ORACLE', 'OUTSCALE', 'SAP', 'SFDC']

      if (realCloudServices.includes(service.bEnd.partnerConfig?.connectType)) {
        destinationType = 'cloud'
      }
    } else if (service.productType === G_PRODUCT_TYPE_IX) {
      destinationType = 'ix'
    }

    const common = {
      optimisedServices: ['GOOGLE', 'SFDC', 'AZURE', 'AWS', 'AWSHC'],
      stepIndex: 0,
      stepStatuses: {},
      saving: false,
      priceStore: false,
      loadingPriceStore: false,
      termPrices: DEFAULT_TERM_PRICES,
      loadingTermPrices: false,
      fresh: !service.productUid,
      showShutdownModal: false,
      summaryShow: {
        cloud: false,
      },
      createConnectionForm: {
        destinationType: destinationType, // Determined above (cloud, private, mx, etc.)
        companySelectedUid: null,
        azureShowAllPorts: false, // So we can validate on this before continuing
        usingAzurePeering: false,
      },
      deals: [],
    }

    // Return IX-specific data if the connection is an IX
    if (destinationType === 'ix') {
      let portUid = service.portUid

      if (service.parentPortUid?.length > 0) {
        portUid = typeof service.parentPortUid === 'string' ? service.parentPortUid : service.parentPortUid[0]
      }

      common.createConnectionForm.serviceObj = {
        productUid: service.productUid || uuid(),
        productName: service.productName || null,
        provisioningStatus: service.provisioningStatus || G_PROVISIONING_DESIGN,
        rateLimit: service.rateLimit || null,
        shutdown: service.shutdown || false,
        // term: service.term || 12, // Uncomment when term functionality has been extended to IXs
        dealUid: service.dealUid || 'None',
        costCentre: service.costCentre || null,
        aEnd: {
          productUid: portUid,
          locationId: service.locationId,
        },
        bEnd: {
          partnerConfig: { complete: null },
          ixType: service.ixType,
        },
        publicGraph: service.publicGraph,
        asn: service.asn,
        vlan: service.vlan,
        macAddress: service.macAddress,
        password: service.password,
        ixPeerMacro: service.ixPeerMacro || null,
      }

      return common
    } else {
      // Return VXC-specific data otherwise
      common.createConnectionForm.serviceObj = {
        productUid: service.productUid || uuid(),
        productName: service.productName || null,
        provisioningStatus: service.provisioningStatus || G_PROVISIONING_DESIGN,
        rateLimit: service.rateLimit || null,
        shutdown: service.shutdown || false,
        term: service.term || 12,
        dealUid: service.dealUid || 'None',
        costCentre: service.costCentre || null,
        aEnd: {
          productUid: service.aEnd.productUid || null, // Port A-End productUid
          vlan: service.aEnd.vlan || 0,
          innerVlan: service.aEnd.innerVlan === null ? -1 : service.aEnd.innerVlan || 0,
          partnerConfig: service.aEnd.partnerConfig || {},
          vNicIndex: service.aEnd.vNicIndex || 0,
        },
        bEnd: {
          productUid: service.bEnd.productUid || null, // Port B-End productUid
          vlan: service.bEnd.vlan || 0,
          innerVlan: service.bEnd.innerVlan === null ? -1 : service.bEnd.innerVlan || 0,
          partnerConfig: service.bEnd.partnerConfig || { complete: null },
          vNicIndex: service.bEnd.vNicIndex || 0,
          awsType: null,
        },
      }

      if (service.bEnd.partnerConfig?.connectType === 'AZURE') {
        const innerVlan = service.bEnd.innerVlan || null

        common.createConnectionForm.serviceObj.bEnd.innerVlan = innerVlan

        if (innerVlan) {
          common.createConnectionForm.usingAzurePeering = true
        }
      }

      return common
    }
  },

  computed: {
    ...mapState('Services', ['services', 'transitEnabledMarkets']),
    ...mapState('Company', { companyUid: state => state.data.companyUid }),
    ...mapState('Auth', { userEmail: state => state.data.email }),
    ...mapGetters('Auth', ['isManagedAccount', 'isPartnerAccount', 'hasFeatureFlag', 'isPartnerVantage']),
    ...mapGetters('Services', ['myPorts', 'targetPorts', 'doesPortHaveUntaggedServices']),
    ...mapGetters('ApplicationContext', ['companyContextLoading']),

    // ----- A-End-related computed properties -----
    // Keep a local state of the A-End port (this includes the _location object).
    aEndPort() {
      if (!this.aEndProductUid) return {}

      return this.myPorts.find(port => port.productUid === this.aEndProductUid) || {}
    },
    aEndProductUid() {
      return this.createConnectionForm.serviceObj.aEnd.productUid
    },
    aEndIsPort() {
      return this.aEndPort.productType === this.G_PRODUCT_TYPE_MEGAPORT
    },
    aEndIsMve() {
      return this.aEndPort.productType === this.G_PRODUCT_TYPE_MVE
    },
    aEndIsMcr() {
      return this.aEndPort.productType === this.G_PRODUCT_TYPE_MCR2
    },
    // The untagged VXC for the selected vNIC on the A-End
    aEndVNicUntaggedVxc() {
      if (!this.aEndIsMve) return null

      return this.aEndPort.associatedVxcs?.find(vxc => {
        if (![vxc.aEnd.productUid, vxc.bEnd.productUid].includes(this.aEndPort.productUid)) return false

        if (vxc.aEnd.productUid === this.aEndPort.productUid) {
          return vxc.aEnd.vNicIndex === this.aEndVNicIndex && [null, -1].includes(vxc.aEnd.innerVlan)
        }

        if (vxc.bEnd.productUid === this.aEndPort.productUid) {
          return vxc.bEnd.vNicIndex === this.aEndVNicIndex && [null, -1].includes(vxc.bEnd.innerVlan)
        }
      }) || null
    },
    showAEndVNics() {
      return this.aEndIsMve && this.aEndVNics.length > 1
    },
    aEndVNicIndex() {
      return this.aEndIsMve ? this.createConnectionForm.serviceObj.aEnd.vNicIndex : null
    },
    aEndVNics() {
      return this.aEndPort.vnics
    },
    aEndDiversityZone() {
      // During port creation config.diversityZone is used but once a port is live,
      // use the assigned diversityZone that is returned.
      return this.aEndPort.config?.diversityZone || this.aEndPort.diversityZone
    },
    aEndMveSizeText() {
      if (!this.aEndIsMve) return null

      const mveSize = this.aEndPort.vendorConfig?.mveLabel || this.aEndPort.mveLabel

      return `${this.$t('general.size')}: ${mveSize}`
    },
    aEndDetailLines() {
      const title = this.aEndPort.title || this.aEndPort.productName || this.$t('general.loading')
      const speed = this.aEndIsMve ? this.aEndMveSizeText : this.aEndPort._speed
      const location = `${this.aEndPort._location?.city ?? ''}, ${this.aEndPort._location?.country ?? ''}`

      return [title, speed, location]
    },
    showAEndVlan() {
      if (this.aEndIsMcr || this.destinationTypeIsIx) return false

      return true
    },
    aEndVlan() {
      return this.createConnectionForm.serviceObj.aEnd.vlan
    },
    aEndInnerVlan() {
      return this.createConnectionForm.serviceObj.aEnd.innerVlan
    },
    /** The list of MCR interfaces configured on the A-end, filtered to remove blank interfaces */
    aEndMcrInterfaces() {
      return this.createConnectionForm.serviceObj.aEnd.partnerConfig.interfaces?.filter(i => i.ipAddresses.length > 0) ?? []
    },

    // ----- B-End-related computed properties -----
    // Keep a local state of the B-End port (this includes the _location object).
    bEndPort() {
      return this.targetPorts.find(port => port.productUid === this.bEndProductUid) || {}
    },
    bEndProductUid() {
      return this.createConnectionForm.serviceObj.bEnd.productUid
    },
    // Service key connections use connectType
    bEndIsMve() {
      return this.bEndPort.productType === this.G_PRODUCT_TYPE_MVE
        || this.bEndPort.connectType === this.G_PRODUCT_TYPE_MVE
    },
    bEndIsMcr() {
      return this.bEndPort.productType === this.G_PRODUCT_TYPE_MCR2
        || this.bEndPort.connectType === 'VROUTER'
    },
    bEndAssociatedTransitVxcs() {
      if (!this.bEndIsMve) return null

      return this.bEndPort.associatedVxcs?.filter(vxc => ([vxc.resources?.csp_connection?.connectType, vxc.connectType].includes('TRANSIT'))) || null
    },
    // The untagged VXC for the selected vNIC on the B-End
    bEndVNicUntaggedVxc() {
      if (!this.bEndIsMve) return null

      return this.bEndPort.associatedVxcs?.find(vxc => {
        if (![vxc.aEnd.productUid, vxc.bEnd.productUid].includes(this.bEndPort.productUid)) return false

        if (vxc.aEnd.productUid === this.bEndPort.productUid) {
          return vxc.aEnd.vNicIndex === this.bEndVNicIndex && [null, -1].includes(vxc.aEnd.innerVlan)
        }

        if (vxc.bEnd.productUid === this.bEndPort.productUid) {
          return vxc.bEnd.vNicIndex === this.bEndVNicIndex && [null, -1].includes(vxc.bEnd.innerVlan)
        }
      }) || null
    },
    bEndVNicIndex() {
      return this.bEndIsMve && !this.destinationTypeIsServiceKey ? this.createConnectionForm.serviceObj.bEnd.vNicIndex : null
    },
    showBEndVNics() {
      return this.bEndIsMve && !this.destinationTypeIsServiceKey && this.bEndPort.vnics.length > 1
    },
    bEndVNics() {
      return this.bEndPort.vnics
    },
    bEndDiversityZone() {
      // During port creation config.diversityZone is used but once a port is live,
      // use the assigned diversityZone that is returned.
      return this.bEndPort.connectType === 'TRANSIT'
        ? null
        : this.bEndPort.config?.diversityZone || this.bEndPort.diversityZone
    },
    bEndMveSizeText() {
      if (!this.bEndIsMve) return null

      const mveSize = this.bEndPort.vendorConfig?.mveLabel || this.bEndPort.mveLabel
      if (!mveSize) return ''

      return `${this.$t('general.size')}: ${mveSize}`
    },
    bEndDetailLines() {
      let details = []

      // Determine the title for the B-End
      const standardTitle = this.$t('connections.select-destination')

      if (this.destinationTypeIsIx && this.bEndIxType) {
        details.push(this.bEndIxType.description || this.bEndIxType.name || standardTitle)
      } else if (this.destinationTypeIsMegaportMarketplace) {
        details.push(this.bEndPort._marketplaceTitle || standardTitle)
      } else {
        details.push(this.bEndPort.title || this.bEndPort.productName || standardTitle)
      }

      // Determine the displayed speed for the B-End if relevant
      if (this.bEndIsMve && this.bEndMveSizeText) {
        details.push(this.bEndMveSizeText)
      } else if (this.bEndIsUserOwned) {
        details.push(this.bEndPort._speed)
      }

      details.push(this.bEndPort._location?.formatted?.short ?? '')

      return details
    },
    showBEndVlan() {
      if (
        this.destinationTypeIsCloud ||
        this.destinationTypeIsIx ||
        !this.bEndIsUserOwned ||
        this.bEndIsMcr ||
        this.destinationTypeIsServiceKey
      ) {
        return false
      }

      return true
    },
    bEndVlan() {
      return this.createConnectionForm.serviceObj.bEnd.vlan
    },
    bEndInnerVlan() {
      return this.createConnectionForm.serviceObj.bEnd.innerVlan
    },
    bEndIsUserOwned() {
      return this.bEndPort._owned
    },
    bEndIxType() {
      if (!this.destinationTypeIsIx) return null

      return this.createConnectionForm.serviceObj.bEnd.ixType
    },
    /**
     * Get list of B-End cloud details fields to show in summary
     */
    bEndCloudSummary() {
      if (this.bEndPort.virtual) return false

      const partnerConfig = this.createConnectionForm.serviceObj.bEnd.partnerConfig

      if (!partnerConfig?.connectType) return false

      if (['DEFAULT', 'VROUTER', 'MVE', 'FRANCEIX'].includes(partnerConfig.connectType)) return false

      // Hide Megaport Internet (TRANSIT) config details if the checkbox to add them is unchecked
      if (partnerConfig.connectType === 'TRANSIT' && !this.hasFeatureFlag('internet_bgp_configuration')) {
        return false
      } else if (partnerConfig.connectType === 'TRANSIT' && this.hasFeatureFlag('internet_bgp_configuration')) {
        if (!partnerConfig._enableBgp) return false
      }

      const partnerConfigObjectKeys = Object.keys(partnerConfig)
      const summaryItems = []

      partnerConfigObjectKeys.forEach(key => {
        // Don't show fields without a value
        if (partnerConfig[key] == null) return
        // Hide specific fields
        if (['complete', 'fixedBandwidths'].includes(key)) return
        // Hide the type from Outscale/Megaport Internet (TRANSIT) since it's not set by the user
        if (key === 'type' && partnerConfig.connectType === 'OUTSCALE') return
        if (key === 'connectType' && partnerConfig.connectType === 'TRANSIT') return

        const formattedKey = key
          // Convert camelCase and underscore_case to separate words
          .replaceAll(/[A-Z]/g, string => ` ${string.toLowerCase()}`)
          .replaceAll('_', ' ')
          // Capitalize ASN, ID, and IP (only if they are not contained in other words)
          .replaceAll(/(^| )(asn|id|ip)( |$)/g, string => string.toUpperCase())

        let formattedValue = partnerConfig[key]

        if (['private', 'public'].includes(formattedValue)) {
          formattedValue = this.$t(`general.${formattedValue}`)
        }

        // Format array value
        if (Array.isArray(formattedValue)) {
          formattedValue = formattedValue.map(value => {
            // Format object value
            if (typeof value === 'object') {
              let keyValuePairString = ''

              // Iterate through all the keys in the object
              for (const innerKey of Object.keys(value)) {
                // Add a comma and a space if the string we are building already has content
                if (keyValuePairString.length) keyValuePairString += ', '
                // Add the current key-value pair to the string
                keyValuePairString += `${innerKey}: ${value[innerKey]}`
              }

              return keyValuePairString
            } else if (typeof value === 'number' || typeof value === 'string') {
              return value
            }

            console.error(value)

            return 'Unrecognised value'
          })

          formattedValue = formattedValue.join(', ')
        }

        if (formattedValue?.length) {
          summaryItems.push({
            key: formattedKey,
            value: formattedValue,
            originalKey: key,
          })
        }
      })

      return summaryItems.sort((a, b) => {
        // Manually move name and connectType fields to top if they exist
        if (a.key === 'connect type' || a.key === 'name') return -1
        if (b.key === 'connect type' || b.key === 'name') return 1

        return a.key > b.key ? 1 : a.key < b.key ? -1 : 0
      })
    },
    /** The list of MCR interfaces configured on the B-end, filtered to remove blank interfaces */
    bEndMcrInterfaces() {
      return this.createConnectionForm.serviceObj.bEnd.partnerConfig.interfaces?.filter(i => i.ipAddresses.length > 0) ?? []
    },

    // ----- Availability/customer-type/permissions-related computed properties -----
    allowNotify() {
      return !this.isPartnerAccount && !this.companyContextLoading
    },
    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    showPriceBox() {
      return this.showPricing
        && this.currentStep.id !== 'final'
        && (this.currentStep.id !== 'configure-connection' || this.destinationTypeIsIx)
    },
    showDeals() {
      // Check if the vantage_partner & vantage_managed account flags are present
      if (!this.isFeatureEnabled('VANTAGE_PARTNER') && !this.isFeatureEnabled('VANTAGE_MANAGED_ACCOUNT')) {
        return false
      }

      // Check if managed account
      if (this.isManagedAccount) {
        return true
      }

      return false
    },
    vxcConnectionPermitted() {
      return this.aEndPort.vxcConnectionPermitted !== false
    },
    transitEnabledMarket() {
      // All markets that offer MVEs are transit-enabled
      if (this.aEndIsMve) return true

      // For Ports and MCRs, transit is enabled for specific markets, which is fetched from the API.
      return this.transitEnabledMarkets
        .filter(({ aConnectType }) => aConnectType === this.aEndPort.connectType)
        .some(({ countryCode }) => countryCode === this.aEndPort?._location?.market)
    },
    transitVxcConnectionPermitted() {
      return !this.disabledFeatures.productMegaportInternet && this.vxcConnectionPermitted && (this.aEndIsMve || this.transitEnabledMarket)
    },
    ixConnectionPermitted() {
      return !this.disabledFeatures.productIX && ![this.G_PRODUCT_TYPE_MVE, this.G_PRODUCT_TYPE_MCR2].includes(this.aEndPort.productType)
    },
    marketplaceConnectionPermitted() {
      return !this.disabledFeatures.marketplace && this.vxcConnectionPermitted
    },
    privateVxcTargetAvailable() {
      return this.services.some(service => {
        if (service.productUid === this.aEndProductUid) return false

        if (service.connectType === 'HIDE') return false

        if (service.companyUid !== this.aEndPort.companyUid) return false

        if (this.doesPortHaveUntaggedServices(service)) return false

        return true
      })
    },

    // ----- Cloud-related computed properties -----
    isCloudServiceKey() {
      // This only applies for cloud destination types
      if (!this.destinationTypeIsCloud) return false

      // If it is a cloud connection and they have their own key system,
      // we need to double check to prevent them from going to the next screen without selecting a port.
      const cloudProviderDetails = CLOUD_ITEMS.find(item => item.companyUids.includes(this.createConnectionForm.companySelectedUid))
      return cloudProviderDetails?.hasKeyValidation === true
    },
    isAzureConnection() {
      return this.createConnectionForm.serviceObj.bEnd?.partnerConfig?.connectType === 'AZURE'
    },
    isGoogleConnection() {
      return this.createConnectionForm.serviceObj.bEnd?.partnerConfig?.connectType === 'GOOGLE'
    },
    isSapWasabiConnection() {
      return this.destinationTypeIsCloud && [SAP_DETAILS.companyUid, WASABI_DETAILS.companyUid].includes(this.createConnectionForm.companySelectedUid)
    },


    // ----- Step-related computed properties -----
    /**
     * This is a dynamic list of steps, it will change as the user moves through it.
     */
    allSteps() {
      const { serviceObj, destinationType } = this.createConnectionForm
      const steps = []

      // STEP: Select Type
      steps.push({
        id: 'select-type',
        title: this.$t('general.select-type'),
        validationRulesKey: 'something',
        validationData: serviceObj,
        complete: (() => {
          if (!serviceObj) return false

          if (this.aEndProductUid && this.aEndPort.locationId && destinationType) return true

          return false
        })(),
      })

      // STEP: Select Port
      steps.push({
        id: 'select-port',
        title: ['mx', 'private'].includes(destinationType)
          ? this.$t('connections.select-destination')
          : this.$t('ports.select-port'),
        validationRulesKey: (() => {
          switch (destinationType) {
            case 'key':
              return 'target-select-key'
            case 'cloud':
              if (this.isSapWasabiConnection) {
                return 'target-select-key'
              }

              return 'target-select-general'
            case 'ix':
              return 'target-select-ix'
            default:
              return 'target-select-general'
          }
        })(),
        validationData: (() => {
          switch (destinationType) {
            case 'key':
              return serviceObj.bEnd.partnerConfig.serviceKey
            case 'ix':
              return serviceObj.bEnd
            case 'cloud':
              return this.isSapWasabiConnection ? serviceObj.bEnd.partnerConfig.serviceKey : serviceObj
            default:
              return serviceObj
          }
        })(),
        complete: (() => {
          if (!serviceObj) return false

          switch (destinationType) {
            case 'key':
              return this.stepStatuses['target-select-key']?.valid
            case 'ix':
              return this.stepStatuses['target-select-ix']?.valid
            case 'cloud':
              if (this.isSapWasabiConnection) {
                return this.stepStatuses['target-select-key']?.valid
              } else if (this.isCloudServiceKey) {
                return this.bEndProductUid !== null
              } else {
                break
              }
            default:
              break
          }

          if (this.aEndProductUid && (this.bEndProductUid || this.bEndIxType)) {
            return true
          }

          return false
        })(),
      })

      // STEP: Connection Details
      steps.push({
        id: 'configure-connection',
        title: this.$t('connections.connection-details'),
        validationRulesKey: 'something',
        validationData: serviceObj,
        complete: (() => {
          if (!serviceObj) return false

          // TODO: Check if this block is in the right place
          if (this.destinationTypeIsCloud) {
            // Why is this block here? If we wanted to check this, shouldn't be part of the previous step complete validation?
            if (
              serviceObj.bEnd.partnerConfig.connectType === 'GOOGLE' &&
              !serviceObj.bEnd.partnerConfig.pairingKey
            ) {
              return false
            }

            // Why is this block here? If we wanted to check this, shouldn't be part of the previous step complete validation?
            if (
              serviceObj.bEnd?.partnerConfig?.connectType === 'AZURE' &&
              !serviceObj.bEnd.partnerConfig.serviceKey
            ) {
              return false
            }
          }

          if (!serviceObj.rateLimit || serviceObj.rateLimit > this.maxRateLimit || serviceObj.rateLimit < 1) {
            return false
          }

          if (this.showAEndVlan) {
            const vlanComponent = this.$refs['serviceObj.aEnd.vlan'] || this.$refs['serviceObj.aEnd.innerVlan']

            if (vlanComponent && !vlanComponent.vlanValid) {
              return false
            }
          }

          if (this.showBEndVlan) {
            const vlanComponent = this.$refs['serviceObj.bEnd.vlan'] || this.$refs['serviceObj.bEnd.innerVlan']

            if (vlanComponent && !vlanComponent.vlanValid) {
              return false
            }
          }

          if (this.usingAzurePeering) {
            let errorMessage = ''

            this.validateAzurePeeringVlan(
              this.createConnectionRules['serviceObj.bEnd.innerVlan'],
              this.bEndInnerVlan,
              message => { errorMessage = message }
            )

            if (errorMessage) return false
          }

          if ( serviceObj.productName?.trim() && serviceObj.rateLimit) {
            return true
          }

          return false
        })(),
      })

      // Add a step if the A-End port is an MCR
      if (this.aEndIsMcr) {
        // STEP: MCR A-End
        steps.push({
          id: 'configure-aend',
          title: this.$t('connections.mcr-end', { end: 'A' }),
          validationRulesKey: 'something',
          validationData: serviceObj,
          complete: (() => {
            return true
          })(),
        })
      }

      if (
        !this.bEndIsMve &&
        this.bEndPort?.connectType &&
        !['DEFAULT', 'AZURE', 'ORACLE', 'GOOGLE', 'NUTANIX', 'SAP', 'FRANCEIX', 'TRANSIT'].includes(this.bEndPort.connectType) &&
        !this.destinationTypeIsServiceKey ||
        (this.bEndPort.connectType === 'TRANSIT' && this.hasFeatureFlag('internet_bgp_configuration'))
      ) {
        // STEP: MCR B-End or Cloud Details or Configure B-End
        steps.push({
          id: 'configure-bend',
          title: (() => {
            // MCR B-End
            if (this.bEndIsMcr) return this.$t('connections.mcr-end', { end: 'B' })

            // Cloud Details
            if (this.destinationTypeIsCloud) return this.$t('connections.cloud-details')

            // Configure B-End
            return this.$t('connections.configure-b-end')
          })(),
          validationRulesKey: 'something',
          validationData: serviceObj,
          complete: (() => {
            if (!serviceObj) return false

            if (this.bEndIsMcr) return true

            return serviceObj.bEnd.partnerConfig.complete ?? true
          })(),
        })
      }

      if (this.destinationTypeIsIx) {
        // STEP: Configure IX
        steps.push({
          id: 'configure-ix',
          title: this.$t('general.configure-thing', { thing: this.$t('productNames.ix') }),
          validationRulesKey: 'something',
          validationData: serviceObj,
          complete: (() => {
            if (!serviceObj) return false

            const ixConfigComponent = this.$refs.ixConfig

            if (ixConfigComponent) {
              const vlanComponent = ixConfigComponent.$refs['serviceObj.vlan']

              if (vlanComponent && !vlanComponent.vlanValid) return false
            }

            if (
              serviceObj.asn &&
              serviceObj.macAddress &&
              typeof serviceObj.publicGraph === 'boolean'
            )
              return true
          })(),
        })
      }

      // STEP: Summary
      steps.push({
        id: 'final',
        title: this.$t('general.summary'),
        validationRulesKey: 'something',
        validationData: serviceObj,
        complete: (() => {
          if (!this.priceStore || !serviceObj) return false

          const connDetails = steps.find(step => step.id === 'configure-connection')
          const aEndStep = steps.find(step => step.id === 'configure-aend')
          const bEndStep = steps.find(step => step.id === 'configure-bend')

          if (!connDetails) return false

          if (aEndStep && bEndStep) {
            return connDetails.complete && aEndStep.complete && bEndStep.complete
          } else if (aEndStep) {
            return connDetails.complete && aEndStep.complete
          } else if (bEndStep) {
            return connDetails.complete && bEndStep.complete
          }

          return true
        })(),
      })

      return steps
    },
    currentStep() {
      return this.allSteps[this.stepIndex]
    },
    canNextStep() {
      return this.currentStep.complete
    },
    finalStep() {
      return this.allSteps.length === this.stepIndex + 1
    },

    // ----- Validation-related computed properties -----
    /**
     * Form rules for multi-step create connection form
     */
    createConnectionRules() {
      let asnRules = {}
      if (['AWS', 'AWSHC', 'SFDC'].includes(this.bEndPort.connectType) && this.aEndIsMcr) {
        asnRules = { required: false }
      } else {
        asnRules = { required: true, validator: this.validateBEndAsn, trigger: 'blur' }
      }

      let customerAsnRules = { required: false }
      if (this.bEndPort.connectType === 'OUTSCALE' && !this.aEndIsMcr) {
        customerAsnRules = { required: true, validator: validateOutscaleAsn, trigger: 'blur' }
      } else if (this.bEndPort.connectType === 'IBM') {
        customerAsnRules = { required: true, validator: validateIbmAsn, trigger: 'blur' }
      } else if (this.bEndPort.connectType === 'TRANSIT' && this.hasFeatureFlag('internet_bgp_configuration')) {
        customerAsnRules = {
          required: this.createConnectionForm.serviceObj.bEnd.partnerConfig._enableBgp,
          validator: validateAsnDefault,
          trigger: 'blur',
        }
      }

      const ipAddressRequired = this.createConnectionForm.serviceObj.bEnd.partnerConfig.type === 'public' && !this.aEndPort.virtual

      return {
        destinationType: { required: true, message: this.$t('validations.destination-type'), trigger: 'change' },
        'serviceObj.bEnd.productUid': {
          required: true,
          message: this.$t('connections.select-destination-port'),
          trigger: 'none',
        },
        'serviceObj.bEnd.awsType': { required: true, message: this.$t('validations.aws-connection-type'), trigger: 'change' },
        companySelectedUid: { required: true, message: this.$t('validations.destination-provider'), trigger: 'none' },
        'serviceObj.productName': [
          { required: true, message: this.$t('validations.connection-name'), trigger: 'blur' },
          { pattern: '^[\\w]{1,}.+', message: this.$t('validations.connection-name-min'), trigger: 'blur' },
          { min: 0, max: 170, message: this.$tc('validations.max-length', 170, { max: 170 }) },
        ],
        'serviceObj.costCentre': { min: 0, max: 255, message: this.$tc('validations.max-length', 255, { max: 255 }) },
        'serviceObj.rateLimit': { required: true, validator: this.validateRatelimit, trigger: this.fixedBandwidths ? 'change' : 'blur' },
        'serviceObj.term': { required: true, trigger: 'blur' },
        'serviceObj.aEnd.vNicIndex': { required: true, message: this.$t('validations.vnic'), trigger: 'none' },
        'serviceObj.bEnd.vNicIndex': { required: true, message: this.$t('validations.vnic'), trigger: 'none' },
        // AMS-IX
        'serviceObj.bEnd.partnerConfig.routeserver': {
          required: true,
          message: this.$t('validations.select-peering'),
          trigger: 'change',
        },
        'serviceObj.bEnd.partnerConfig.peering_policy': { required: true, message: this.$t('validations.please-select', { thing: this.$t('connections.peering-policy') }), trigger: 'change' },
        // This one needs to handle AMS-IX and AWS and SFDC, so we have code below to adjust the requiredness as necessary
        'serviceObj.bEnd.partnerConfig.asn': asnRules,
        'serviceObj.bEnd.partnerConfig.tcp_id': { required: true, message: this.$t('validations.please-select', { thing: this.$t('general.technical-contact') }), trigger: 'change' },
        'serviceObj.bEnd.partnerConfig.noc_id': { required: true, message: this.$t('validations.please-select', { thing: this.$t('general.noc-contact') }), trigger: 'change' },
        'serviceObj.bEnd.partnerConfig.phone': [
          { required: true, message: this.$t('validations.please-enter', { thing: this.$t('general.contact-phone') }), trigger: 'blur' },
          { pattern: '^\\+[0-9 \\-]+$', message: this.$t('validations.phone-format'), trigger: 'blur' },
        ],
        'serviceObj.bEnd.partnerConfig.policy_url': { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.policy-url') }), trigger: 'blur' },
        'serviceObj.bEnd.partnerConfig.peering_email': { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.peering-email') }), trigger: 'blur' },
        'serviceObj.bEnd.partnerConfig.web_url': { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.company-url') }), trigger: 'blur' },
        'serviceObj.bEnd.partnerConfig.company_email': { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.company-email') }), trigger: 'blur' },
        'serviceObj.bEnd.partnerConfig.address1': { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.street-address') }), trigger: 'blur' },
        'serviceObj.bEnd.partnerConfig.city': { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.city') }), trigger: 'blur' },
        'serviceObj.bEnd.partnerConfig.state': { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.state-province') }), trigger: 'blur' },
        'serviceObj.bEnd.partnerConfig.postcode': { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.postcode-zip') }), trigger: 'blur' },
        'serviceObj.bEnd.partnerConfig.country': { required: true, message: this.$t('validations.please-select', { thing: this.$t('connections.country') }), trigger: 'change' },
        // AWS
        'serviceObj.bEnd.partnerConfig.name': [
          { required: this.bEndPort.connectType !== 'OUTSCALE', message: this.$t('validations.please-enter', { thing: this.$t('connections.aws-connection-name') }), trigger: 'blur' },
          { max: 100, message: this.$tc('validations.max-length', 100, { max: 100 }), trigger: 'blur' },
        ],
        // AWS
        'serviceObj.bEnd.partnerConfig.type': { required: true, message: this.$t('validations.please-select', { thing: this.$t('connections.type') }), trigger: 'change' },
        'serviceObj.bEnd.partnerConfig.ownerAccount': [
          { required: true, message: this.$t('validations.please-enter', { thing: this.$t('connections.aws-owner-acct-id') }), trigger: 'change' },
          {
            len: 12,
            message: this.$t('validations.aws-account'),
            trigger: 'change',
            transform(value) {
              if (!value) return false
              return value.replace(/[^0-9]+/g, '')
            },
          },
        ],
        'serviceObj.bEnd.partnerConfig.amazonAsn': { validator: validateAwsAsn, trigger: 'blur' },
        'serviceObj.bEnd.partnerConfig.authKey': { pattern: '^[^\\s]{6,24}$', message: this.$t('validations.min-max-length', { min: 6, max: 24 }), trigger: 'blur' },
        // See watch on public for changing whether this is required
        'serviceObj.bEnd.partnerConfig.customerIpAddress': [
          { required: ipAddressRequired, message: this.$t('validations.required', { thing: this.$t('connections.customer-ip') }), trigger: 'blur' },
          { pattern: `^${IP_CIDR_REGEX}$`, message: this.$t('validations.ip-cidr-format'), trigger: 'blur' },
        ],
        'serviceObj.bEnd.partnerConfig.amazonIpAddress': [
          { required: ipAddressRequired, message: this.$t('validations.required', { thing: this.$t('connections.amazon-ip') }), trigger: 'blur' },
          { pattern: `^${IP_CIDR_REGEX}$`, message: this.$t('validations.ip-cidr-format'), trigger: 'blur' },
        ],
        'serviceObj.bEnd.partnerConfig.prefixes': {
          pattern: `^(${IP_CIDR_REGEX})(,\\s*${IP_CIDR_REGEX})*$`,
          message: this.$t('validations.ip-list'),
          trigger: 'blur',
        },
        // Alibaba, Outscale and IBM
        'serviceObj.bEnd.partnerConfig.account_id': [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t(`connections.${this.bEndPort.connectType?.toLowerCase()}-account`) }),
            trigger: 'blur',
          },
          {
            // IBM requires a 32 digit hexadecimal number, alibaba and outscale only require anything within 8 to 16 chars
            pattern: this.bEndPort.connectType === 'IBM' ? '^[A-Fa-f0-9]{32}$' : '^[A-Za-z0-9]{8,16}$',
            message: this.bEndPort.connectType === 'IBM' ?
              this.$t('validations.enter-valid-auth-key', { thing: this.$t('ibm.account-id-validation') }) :
              this.$t('validations.min-max-length', { min: 8, max: 16 }),
            trigger: 'blur',
          },
        ],
        // Outscale, IBM and TRANSIT
        'serviceObj.bEnd.partnerConfig.customer_asn': customerAsnRules,
        'serviceObj.bEnd.partnerConfig.customer_ip_address': [
          { required: ipAddressRequired, message: this.$t('validations.required', { thing: this.$t('connections.customer-ip') }), trigger: 'blur' },
          { pattern: `^${IP_CIDR_REGEX}$`, message: this.$t('validations.ip-cidr-format'), trigger: 'blur' },
        ],
        'serviceObj.bEnd.partnerConfig.provider_ip_address': [
          { required: ipAddressRequired, message: this.$t('validations.required', { thing: this.$t('connections.outscale-ip') }), trigger: 'blur' },
          { pattern: `^${IP_CIDR_REGEX}$`, message: this.$t('validations.ip-cidr-format'), trigger: 'blur' },
        ],
        // Outscale
        'serviceObj.bEnd.partnerConfig.auth_key': [{ pattern: '^[a-zA-Z\\d]{6,24}$', message: this.$t('validations.min-max-alphanumeric', { min: 6, max: 24 }), trigger: 'blur' }],
        // Azure and Nutanix
        'serviceObj.bEnd.partnerConfig.serviceKey': [
          { required: true, message: this.$t('validations.required', { thing: this.$t('services.service-key') }), trigger: 'change' },
          { validator: this.validateServiceKey, trigger: 'change' },
        ],
        'serviceObj.bEnd.partnerConfig.peers': { required: true, message: this.$t('validations.select-peer-type'), trigger: 'change' },
        // Google
        'serviceObj.bEnd.partnerConfig.pairingKey': [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('connections.pairing-key') }),
            trigger: 'change',
          },
          {
            pattern: '^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/[A-Za-z0-9-]+/[A-Za-z0-9-]+$',
            message: this.$t('validations.pairing-key-format-invalid'),
            trigger: 'change',
          },
          { validator: this.validateGooglePairingKey, trigger: 'change' },
        ],
        // Oracle
        'serviceObj.bEnd.partnerConfig.virtualCircuitId': [
          { required: true, message: this.$t('validations.required', { thing: this.$t('connections.virtual-circuit-id') }), trigger: 'change' },
          { pattern: '.*(virtualcircuit).*', message: this.$t('validations.virtual-circuit-format-invalid'), trigger: 'change' },
          { validator: this.validateOracleKey, trigger: 'change' },
        ],
        'serviceObj.asn': { required: true, validator: validateIxAsn, trigger: 'blur' },
        'serviceObj.password': { validator: validateBGPPassword, trigger: 'blur' },
        'serviceObj.publicGraph': { required: true },
        'serviceObj.ixPeerMacro': { required: false, validator: validateIxPeerMacro, trigger: 'blur' },
        azureShowAllPorts: { validator: this.validateUsingServiceKey, trigger: 'none' },
        ...macAddressValidationRule('serviceObj.macAddress', true, 'blur'),
        'serviceObj.aEnd.vlan': { validator: this.validateVlan, trigger: 'blur' },
        'serviceObj.bEnd.vlan': { validator: this.validateVlan, trigger: 'blur' },
        'serviceObj.aEnd.innerVlan': { validator: this.validateVlan, trigger: 'blur' },
        // This is also used as the Azure peering VLAN field for Azure connections hence its requirement
        'serviceObj.bEnd.innerVlan': {
          required: this.usingAzurePeering,
          validator: this.isAzureConnection ? this.validateAzurePeeringVlan : this.validateVlan,
          trigger: 'blur',
        },
        'serviceObj.vlan': { validator: this.validateVlan, trigger: 'blur' },
      }
    },
    validateServiceKeyNextStep() {
      if (
        this.currentStep.id === 'select-port' &&
        this.currentStep.validationRulesKey === 'target-select-key' &&
        !this.currentStep.complete
      ) {
        return false
      }

      return true
    },
    validateGoogleNextStep() {
      if (this.currentStep.id === 'configure-connection') {
        // This will happen if the user has entered a key but then gone into the show all ports section
        if (
          this.createConnectionForm.serviceObj.bEnd?.partnerConfig?.connectType === 'GOOGLE' &&
          !this.createConnectionForm.serviceObj.bEnd.partnerConfig.pairingKey
        ) {
          return false
        }
      }

      return true
    },
    validateIxNextStep() {
      if (this.destinationTypeIsIx && this.currentStep.id === 'select-port') {
        if (!this.bEndIxType) return false
      }

      return true
    },
    validateCloudServiceKeyNextStep() {
      if (this.currentStep.id === 'select-port' && this.isCloudServiceKey && !this.currentStep.complete) {
        return false
      }

      return true
    },

    // ----- Rate-limit-related computed properties -----
    minRateLimit() {
      return minVXCSpeed(this.aEndPort, this.bEndPort)
    },
    maxRateLimit() {
      const serviceObj = this.createConnectionForm.serviceObj
      const aEndReady = this.aEndPort
      const bEndReady = this.bEndPort
      const ixTypeReady = serviceObj.bEnd?.ixType !== undefined

      if (aEndReady && ixTypeReady && this.destinationTypeIsIx) {
        // Calculate maximum rate limit for IX connection
        return maxIXSpeed(this.aEndPort, serviceObj.bEnd.ixType)
      } else if (aEndReady && bEndReady) {
        let speeds = [maxVXCSpeed(this.aEndPort, this.bEndPort)]

        if (serviceObj.aEnd.partnerConfig?.maxRateLimit && serviceObj.aEnd.partnerConfig?.connectType !== 'AZURE') {
          speeds.push(serviceObj.aEnd.partnerConfig.maxRateLimit)
        }

        if (serviceObj.bEnd.partnerConfig?.maxRateLimit && serviceObj.bEnd.partnerConfig?.connectType !== 'AZURE') {
          speeds.push(serviceObj.bEnd.partnerConfig.maxRateLimit)
        }

        // Return the minimum of all speeds
        return Math.min(...speeds)
      } else {
        return false
      }
    },
    fixedRate() {
      return !!this.createConnectionForm.serviceObj.bEnd.partnerConfig?.fixedPortSpeed ||
        this.createConnectionForm.serviceObj.bEnd.partnerConfig?.connectType === 'NUTANIX'
    },
    showRateLimitWarning() {
      return ['GCI'].includes(this.createConnectionForm.serviceObj.bEnd.partnerConfig?.connectType)
    },
    higherSpeedUrl() {
      return getSurveyLink('higherSpeed', {
        email: this.userEmail,
        aEnd: this.aEndPort._location?.formatted?.long ?? 'Unknown',
        bEnd: this.bEndPort._location?.formatted?.long ?? 'Unknown',
      })
    },

    // ----- Destination-type-related computed properties -----
    destinationTypeIsIx() {
      return this.createConnectionForm.destinationType === 'ix'
    },
    destinationTypeIsCloud() {
      return this.createConnectionForm.destinationType === 'cloud'
    },
    destinationTypeIsMegaportMarketplace() {
      return this.createConnectionForm.destinationType === 'mx'
    },
    destinationTypeIsServiceKey() {
      return this.createConnectionForm.destinationType === 'key'
    },
    destinationTypeIsTransit() {
      return this.createConnectionForm.destinationType === 'transit'
    },

    // ----- Other computed properties -----
    connectionProductUid() {
      return this.createConnectionForm.serviceObj.productUid
    },
    componentTitle() {
      return this.$t(this.fresh ? 'general.new-type' : 'general.edit-configured-type', { type: this.$t('connections.connection') })
    },
    /**
     * Determines whether the MCR connection configuration is auto-configured (shown in the summary step)
     */
    autoConfigured() {
      const connectType = this.createConnectionForm.serviceObj.bEnd.partnerConfig.connectType

      switch (connectType) {
        case 'AZURE':
        case 'AWS':
        case 'AWSHC':
        case 'GOOGLE':
        case 'SFDC':
        case 'OUTSCALE':
        case 'IBM':
        case 'TRANSIT':
          return true
        default:
          return false
      }
    },
    fixedBandwidths() {
      if (this.createConnectionForm.serviceObj.bEnd.partnerConfig?.fixedBandwidths) {
        return this.createConnectionForm.serviceObj.bEnd.partnerConfig.fixedBandwidths
      }

      return false
    },
    bgpDefaultStateShutdown() {
      try {
        const mcr = this.aEndPort

        if (mcr.provisioningStatus === G_PROVISIONING_DESIGN) {
          return mcr.config.bgpShutdownDefault || false
        }

        return mcr.resources.virtual_router.bgpShutdownDefault
      } catch (error) {
        return false
      }
    },
    showVLANWarning() {
      if (this.aEndPort.virtual) return false

      return ['GCI'].includes(this.createConnectionForm.serviceObj.bEnd.partnerConfig?.connectType)
    },
    iconType() {
      let aEnd = this.G_PRODUCT_TYPE_MEGAPORT

      if (this.aEndIsMve) {
        aEnd = this.G_PRODUCT_TYPE_MVE
      } else if (this.aEndIsMcr) {
        aEnd = 'MCR'
      } else if (this.aEndPort.aggregationId) {
        aEnd = 'LAG'
      }

      let bEnd = this.G_PRODUCT_TYPE_MEGAPORT

      if (this.destinationTypeIsServiceKey) {
        bEnd = 'ServiceKey'
      } else if (this.destinationTypeIsMegaportMarketplace) {
        bEnd = 'Marketplace'
      } else if (this.createConnectionForm.serviceObj.productType === this.G_PRODUCT_TYPE_IX || this.destinationTypeIsIx) {
        bEnd = this.G_PRODUCT_TYPE_IX
      } else if (this.bEndPort.connectType === 'TRANSIT') {
        bEnd = 'MegaportInternet'
      } else if (this.bEndIsMve) {
        bEnd = this.G_PRODUCT_TYPE_MVE
      } else if (this.bEndIsMcr) {
        bEnd = 'MCR'
      } else if (this.bEndPort.aggregationId) {
        bEnd = 'LAG'
      }

      return { aEnd, bEnd }
    },
    rightDetailCardDisabled() {
      const bEndHasBeenSelected = this.bEndPort.productUid || this.bEndIxType

      return !bEndHasBeenSelected
    },
    defaultConnectionName() {
      if (this.bEndPort.connectType === 'TRANSIT') {
        return this.$t('connections.vxc-name-default', { aEnd: this.aEndPort.title, bEnd: this.bEndPort.title })
      }

      return ''
    },
    usingAzurePeering() {
      return this.isAzureConnection && this.createConnectionForm.usingAzurePeering
    },
    addConnectionButtonContent() {
      const type = this.$t(this.destinationTypeIsIx ? 'productNames.ix' : 'productNames.vxc')

      if (this.fresh) {
        return this.$t('general.add-type', { type })
      } else {
        return this.$t('general.update-type', { type })
      }
    },
    isLoadingPrices() {
      return this.loadingPriceStore || this.loadingTermPrices
    },
    showBgpConfig() {
      return this.bEndPort.connectType === 'SFDC'
        || (this.bEndPort.connectType === 'TRANSIT' && this.hasFeatureFlag('internet_bgp_configuration'))
    },
  },

  watch: {
    $route: {
      deep: true,
      handler() {
        this.initState()
      },
    },
    services: {
      deep: true,
      handler(newValue) {
        // Need to bail if the parent port is gone
        if (newValue) {
          const connectedPort = newValue.find(port => port.productUid === this.aEndProductUid)

          if (!connectedPort) {
            const props = {
              title: this.$t('ports.port-deleted'),
              message: this.$t('connections.connected-port-removed'),
              type: 'error',
              duration: 3000,
            }

            if (this.allowNotify) this.$notify(props)

            this.$router.push(resolveServicesPage())
          }
        }

        // Never need to bail if we weren't already saved
        if (this.fresh) return

        const newServices = newValue ? [].concat(...newValue.map(port => [...port.associatedVxcs, ...port.associatedIxs])) : []
        const newConnection = newServices.find(service => service.productUid === this.connectionProductUid)

        // We only want to do it if it was in the services before but it's not now,
        // so we don't bail them out if something happens on initial edit.
        if (!newConnection) {
          const props = {
            title: this.$t('connections.deleted'),
            message: this.$t('services.service-deleted'),
            type: 'error',
            duration: 3000,
          }

          if (this.allowNotify) this.$notify(props)

          this.$router.push(resolveServicesPage())
        }
      },
    },
    'createConnectionForm.serviceObj.bEnd.productUid'() {
      if (this.$refs.createConnectionForm && document.querySelector('[for="serviceObj.bEnd.partnerConfig.serviceKey.key"]')) {
        this.$refs.createConnectionForm.validateField('serviceObj.bEnd.partnerConfig.serviceKey.key')
      }
    },
    'createConnectionForm.serviceObj.bEnd.partnerConfig.serviceKey'() {
      if (this.$refs.createConnectionForm && document.querySelector('[for="serviceObj.bEnd.partnerConfig.serviceKey"]')) {
        this.$refs.createConnectionForm.validateField('serviceObj.bEnd.partnerConfig.serviceKey')
      }
    },
    'createConnectionForm.serviceObj.bEnd.partnerConfig.pairingKey'() {
      if (this.$refs.createConnectionForm && document.querySelector('[for="serviceObj.bEnd.partnerConfig.pairingKey"]')) {
        this.$refs.createConnectionForm.validateField('serviceObj.bEnd.partnerConfig.pairingKey')
      }
    },
    'createConnectionForm.serviceObj.bEnd.partnerConfig.virtualCircuitId'() {
      if (this.$refs.createConnectionForm && document.querySelector('[for="serviceObj.bEnd.partnerConfig.virtualCircuitId"]')) {
        this.$refs.createConnectionForm.validateField('serviceObj.bEnd.partnerConfig.virtualCircuitId')
      }
    },
    'createConnectionForm.serviceObj.shutdown'(shutdown) {
      this.showShutdownModal = shutdown
    },
    'createConnectionForm.serviceObj': {
      handler() {
        if (this.showPricing) {
          if (this.currentStep.id === 'configure-connection' && !this.destinationTypeIsIx) {
            this.updateTermPrices()
          } else {
            this.getPrice()
          }
        }
        const serviceObj = this.createConnectionForm.serviceObj

        if (
          serviceObj.rateLimit === null &&
          serviceObj.bEnd.partnerConfig?.connectType === 'AZURE' &&
          serviceObj.bEnd.partnerConfig.maxRateLimit
        ) {
          const portSpeed = this.aEndPort.speed || this.aEndPort.portSpeed || undefined
          this.createConnectionForm.serviceObj.rateLimit = this.aEndPort.productType === this.G_PRODUCT_TYPE_MVE
            ? this.createConnectionForm.serviceObj.bEnd.partnerConfig.maxRateLimit
            : Math.min(
              this.createConnectionForm.serviceObj.bEnd.partnerConfig.maxRateLimit,
              portSpeed
            )
        }

        if (
          serviceObj.bEnd.partnerConfig.connectType === 'NUTANIX' &&
          serviceObj.bEnd.partnerConfig.fixedPortSpeed
        ) {
          serviceObj.rateLimit = serviceObj.bEnd.partnerConfig.fixedPortSpeed
        }
      },
      deep: true,
    },
    createConnectionForm: {
      deep: true,
      immediate: true,
      handler() {
        this.updateStepStatuses()
      },
    },
    'createConnectionForm.azureShowAllPorts'() {
      this.$refs.createConnectionForm.clearValidate()
    },
    fixedBandwidths() {
      if (this.fixedBandwidths && !this.fixedBandwidths.includes(this.createConnectionForm.serviceObj.rateLimit)) {
        this.createConnectionForm.serviceObj.rateLimit = null
      }
    },
    aEndVNicIndex() {
      if (this.aEndIsMve) this.setMveOuterVlanForEnd('a')
    },
    bEndVNicIndex() {
      if (this.bEndIsMve && !this.destinationTypeIsServiceKey) this.setMveOuterVlanForEnd('b')
    },
    currentStep(newStep, oldStep) {
      if (this.showPricing && newStep.id !== oldStep.id) {
        // Ensure we fetch the price into priceStore if we've only been fetching it into termPrices
        if (oldStep.id === 'configure-connection') {
          this.getPrice()

        // And vice-versa
        } else if (newStep.id === 'configure-connection') {
          this.updateTermPrices()
        }
      }
    },
  },

  created() {
    this.initState()
    this.fetchDeals()
    this.updateTermPrices()
  },

  mounted() {
    if (this.fresh) {
      if (this.aEndIsMve && !this.aEndVNicUntaggedVxc) {
        this.createConnectionForm.serviceObj.aEnd.innerVlan = -1
      }
    }
  },

  methods: {
    ...mapActions('Services', ['addConnectionToService', 'updateExistingConnection']),
    ...mapMutations('Notifications', ['notifyBad']),
    kebabCase,
    resolveServicesPage,
    scopedPriceBook,

    // ----- Validation-related methods -----
    validateRatelimit(_rule, _value, callback) {
      // Make sure we have the latest value in internal state before validating
      this.$refs.rateLimitInput?.debouncedEmitInput.flush()
      if (this.$refs.rateLimitInput?.invalid) {
        callback(new Error(this.$refs.rateLimitInput.invalid))
      } else {
        callback()
      }
    },
    validateUsingServiceKey(_rule, value, callback) {
      if (typeof value !== 'boolean' || value) {
        callback(this.$t('validations.use-service-key'))
      } else if (typeof value === 'boolean' && this.createConnectionForm.serviceObj.bEnd.partnerConfig.serviceKey) {
        // We will already get the error from the key input field
        callback()
      } else {
        callback()
      }
    },
    validateServiceKey(_rule, value, callback) {
      // Need to work out whether it's a Nutanix key or an Azure key, since they have different validation rules.
      const companySelectedUid = this.createConnectionForm.companySelectedUid

      if (companySelectedUid) {
        const cloudItem = CLOUD_ITEMS.find(item => item.companyUids.includes(companySelectedUid))

        if (cloudItem) {
          const connectType = cloudItem.connectType

          switch (connectType) {
            case 'NUTANIX':
              if (!value.match(/^([0-9a-zA-Z]{3}-)?[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)) {
                callback(this.$t('validations.nutanix-key-format'))
                return true
              }
              if (!this.createConnectionForm.serviceObj.bEnd.partnerConfig.validKey) {
                callback(this.$t('validations.invalid-service-key'))
                return true
              }
              break
            case 'AZURE':
              if (!value.match('^.{32,36}$')) {
                callback(this.$t('validations.azure-key-format'))
                return true
              }
              if (!this.createConnectionForm.serviceObj.bEnd.partnerConfig.validKey) {
                callback(this.$t('validations.invalid-service-key'))
                return true
              }
              break
            // @todo should we pick up 'SAP' here?
            default:
              break
          }
        }
      }
      callback()
    },
    validateAzurePeeringVlan(rule, value, callback) {
      if (!rule.required) {
        callback()
        return true
      }

      const vlan = Number.parseInt(value)

      if (Number.isNaN(vlan) || !vlan) {
        callback(this.$t('validations.required', { thing: this.$t('connections.vlan-value') }))
      } else if (vlan < MIN_AZURE_VLAN || vlan > MAX_AZURE_VLAN) {
        callback(this.$t(`validations.vlan-range`, { minVlan: MIN_AZURE_VLAN, maxVlan: MAX_AZURE_VLAN }))
      } else {
        callback()
      }
    },
    validateVlan(rule, _value, callback) {
      // For this to work the field name must match the ref
      let vlanComponent = this.$refs[rule.field]

      if (!vlanComponent) {
        const ixConfigComponent = this.$refs.ixConfig

        vlanComponent = ixConfigComponent.$refs[rule.field]
      }

      if (!vlanComponent) {
        captureSentryError(new Error('Field name must match ref'))

        callback('false')

        return
      }

      // Make sure the vlan is checked even if the user hasn't typed it in
      if (vlanComponent.fresh) {
        vlanComponent.triggerValidation()
      }

      if (vlanComponent.vlanValid) {
        callback()
      } else if (vlanComponent.checkingVlan) {
        vlanComponent.$on('vlanCheckComplete', () => {
          if (vlanComponent.vlanValid) {
            callback()
          } else {
            callback('false')
          }

          vlanComponent.$off('vlanCheckComplete')
        })
      } else {
        callback('false')
      }
    },
    validateBEndAsn(rule, value, callback) {
      // Special case for AWS as per ENG-8123
      if (this.bEndPort.connectType === 'AWS') {
        validateAwsAsn(rule, value, callback)
      } else if (this.bEndPort.connectType === 'AMSIX') {
        validateIxAsn(rule, value, callback)
      } else {
        validateAsnDefault(rule, value, callback)
      }
    },
    validateGooglePairingKey(_rule, _value, callback) {
      if (!this.createConnectionForm.serviceObj.bEnd.partnerConfig.validKey) {
        callback(this.$t('validations.pairing-key-invalid'))

        return true
      }

      callback()
    },
    validateOracleKey(_rule, _value, callback) {
      if (!this.createConnectionForm.serviceObj.bEnd.partnerConfig.validKey) {
        callback(this.$t('validations.virtual-circuit-invalid'))

        return true
      }

      callback()
    },

    // ----- Service-end-related methods -----
    aInnerVlanEdited(value) {
      this.createConnectionForm.serviceObj.aEnd.innerVlan = value || 0

      this.$refs.createConnectionForm.clearValidate('serviceObj.aEnd.innerVlan')
      this.$refs.createConnectionForm.clearValidate('serviceObj.bEnd.innerVlan')
    },
    bInnerVlanEdited(value) {
      this.createConnectionForm.serviceObj.bEnd.innerVlan = value || 0

      if (this.usingAzurePeering && !value) {
        // We need the default VLAN value for Azure to be null not 0 since we don't want a random tag assignment
        this.createConnectionForm.serviceObj.bEnd.innerVlan = null
      }

      this.$refs.createConnectionForm.clearValidate('serviceObj.bEnd.innerVlan')
      this.$refs.createConnectionForm.clearValidate('serviceObj.aEnd.innerVlan')
    },
    bEndChosen(item) {
      if (this.fresh) {
        if (this.aEndIsMve) {
          this.createConnectionForm.usingAzurePeering = item.partnerConfig.connectType === 'AZURE'
        }

        this.$nextTick(() => {
          if (this.bEndIsMve && !this.bEndVNicUntaggedVxc) {
            this.createConnectionForm.serviceObj.bEnd.innerVlan = -1
          }
        })

        if (item.partnerConfig.connectType === 'AZURE') {
          this.createConnectionForm.serviceObj.bEnd.innerVlan = null
        }
      }

      this.$refs.createConnectionForm?.clearValidate('serviceObj.bEnd.productUid')

      this.createConnectionForm.serviceObj.bEnd = item

      if (this.bEndIsMve) this.setMveOuterVlanForEnd('b')

      if (!this.createConnectionForm.serviceObj.productName) {
        this.createConnectionForm.serviceObj.productName = this.defaultConnectionName
      }
    },
    determineVlanSummaryDisplayValue(vlan) {
      if ([null, -1].includes(vlan)) {
        return this.$t('connections.untagged')
      } else if (vlan) {
        return vlan
      } else {
        return this.$t('general.unspecified')
      }
    },
    determineVNicLabel(end) {
      return this.$t('connections.end-vnic', { end })
    },
    setMveOuterVlanForEnd(prefix) {
      if (prefix === 'b' && this.destinationTypeIsServiceKey) return false

      const endPort = this[`${prefix}EndPort`]

      if (endPort.provisioningStatus === G_PROVISIONING_DESIGN) return

      this.createConnectionForm.serviceObj[`${prefix}End`].vlan = this[`${prefix}EndVNics`][this[`${prefix}EndVNicIndex`]].vlan
    },
    handleVNicChangeForEnd(prefix) {
      let defaultVlanValue = !this[`${prefix}EndVNicUntaggedVxc`] ? -1 : 0

      this.createConnectionForm.serviceObj[`${prefix}End`].innerVlan = defaultVlanValue

      this.$nextTick(() => {
        const vlanComponent = this.$refs[`serviceObj.${prefix}End.innerVlan`]

        if (vlanComponent) vlanComponent.triggerValidation()
      })
    },
    checkVNicExists(end, index) {
      // Non-default vNIC index was deleted from MVE, so unset previous value to ensure select shows correctly.
      // For easier searchability: aEndVNicIndex, bEndVNicIndex, aEndVnics, bEndVnics.
      if (this[`${end}VNicIndex`] !== 0 && !this[`${end}VNics`]?.[index]) this.createConnectionForm.serviceObj[end].vNicIndex = 0
    },

    // ----- Step-related methods -----
    handleStepClick(_event, index) {
      // If the step the user is trying to navigate to is not ready, alert them and do nothing.
      if (!this.stepReady(index)) {
        this.$alert(this.$t('general.step-unavailable'), this.$t('general.not-available'), {
          showClose: false,
          type: 'warning',
        })

        return false
      }

      // Else, if the step is ready, navigate to the chosen step and trigger the necessary validations.
      this.goToStep(index)
    },
    calcStepStatus(_step, index) {
      if (!this.stepReady(index)) {
        return 'wait'
      }
      if (index < this.stepIndex) {
        return 'success'
      }
      if (index === this.stepIndex) {
        return 'process'
      }
      if (index === this.stepIndex + 1) {
        return 'finish'
      }
    },
    updateStepStatuses() {
      const cv = new ConnectionValidator(this)

      cv.setSupportingData('target-select-key', { productUid: this.bEndProductUid })

      for (const step of this.allSteps) {
        cv.validate(step.validationRulesKey, step.validationData)
          .then(type => {
            Vue.set(this.stepStatuses, type, { valid: true })
          })
          .catch(({ type, errors }) => {
            Vue.set(this.stepStatuses, type, { valid: false, errors: errors })
          })
      }
    },
    goToNextStep() {
      if (this.finalStep) {
        return this.addToCart()
      }

      if (!this.validateServiceKeyNextStep) {
        const props = {
          title: this.$t('validations.failed'),
          message: this.$t('validations.invalid-service-key'),
          type: 'error',
          duration: 3000,
        }

        this.$notify(props)

        return false
      }

      if (!this.validateGoogleNextStep) {
        const props = {
          title: this.$t('validations.please-enter', { thing: this.$t('connections.pairing-key') }),
          message: this.$t('validations.google-pairing-key'),
          type: 'warning',
          duration: 3000,
        }

        this.$notify(props)

        return false
      }

      if (!this.validateIxNextStep) {
        this.$refs.targetSelectIx.validate()

        const props = {
          title: this.$t('validations.failed'),
          message: this.$t('validations.correct-issues'),
          type: 'error',
          duration: 3000,
        }

        this.$notify(props)

        return false
      }

      this.$refs.createConnectionForm.validate(valid => {
        let vlanValid = true

        if (this.showAEndVlan) {
          const vlanComponent = this.$refs['serviceObj.aEnd.vlan'] || this.$refs['serviceObj.aEnd.innerVlan']

          if (vlanComponent && !vlanComponent.vlanValid) {
            vlanValid = false
          }
        }

        if (this.showBEndVlan) {
          const vlanComponent = this.$refs['serviceObj.bEnd.vlan'] || this.$refs['serviceObj.bEnd.innerVlan']

          if (vlanComponent && !vlanComponent.vlanValid) {
            vlanValid = false
          }
        }

        if (!valid || !vlanValid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }

          this.$notify(props)

          return false
        }

        if (!this.validateCloudServiceKeyNextStep) {
          const props = {
            title: this.$t('validations.invalid-key'),
            message: this.$t('validations.enter-valid-key'),
            type: 'error',
            duration: 3000,
          }

          this.$notify(props)

          return false
        }

        this.trackButtonClick('next')
        this.goToStep(this.stepIndex + 1)
      })
    },
    goToLastStep() {
      this.trackButtonClick('back')
      this.goToStep(this.stepIndex - 1)
    },
    goToStep(step) {
      this.stepIndex = step

      this.$nextTick(() => {
        if (this.showAEndVlan) {
          const vlanComponent = this.$refs['serviceObj.aEnd.vlan']

          vlanComponent?.triggerValidation()
        }

        if (this.showBEndVlan) {
          const vlanComponent = this.$refs['serviceObj.bEnd.vlan']

          vlanComponent?.triggerValidation()
        }

        // If moving to the ixConfig step, make sure we validate VLAN on arrival to ensure navigation doesn't break.
        const ixConfigComponent = this.$refs.ixConfig

        if (ixConfigComponent) {
          const vlanComponent = ixConfigComponent.$refs['serviceObj.vlan']

          vlanComponent?.triggerValidation()
        }
      })
    },
    stepReady(stepIndex) {
      // The first step is always available
      if (stepIndex === 0) return true

      // For a step to be ready, all of its preceding steps must be complete.
      for (let i = 0; i < stepIndex; i++) {
        const step = this.allSteps[i]

        if (!step.complete) return false
      }

      return true
    },

    // ----- Pricing/Ordering-related methods -----
    async getPrice() {
      this.priceStore = false

      if (
        !this.aEndProductUid ||
        (!this.destinationTypeIsIx && !this.bEndProductUid) ||
        !this.createConnectionForm.serviceObj.rateLimit
      ) return

      this.loadingPriceStore = true

      try {
        if (this.destinationTypeIsIx) {
          if (!this.bEndIxType || !this.aEndPort.locationId) return

          this.priceStore = await this.scopedPriceBook()
            .ix(
              this.bEndIxType.name,
              this.aEndPort.locationId,
              this.createConnectionForm.serviceObj.rateLimit,
              // this.createConnectionForm.serviceObj.term, // Uncomment when term functionality has been extended to IXs
              this.createConnectionForm.serviceObj.buyoutPort,
              this.connectionProductUid
            )
        } else {
          this.priceStore = await this.scopedPriceBook()
            .vxc(
              this.aEndPort.locationId,
              this.bEndPort.locationId,
              this.createConnectionForm.serviceObj.rateLimit,
              this.createConnectionForm.serviceObj.term,
              this.aEndPort.productType,
              this.bEndPort.connectType,
              this.aEndPort.buyoutPort,
              this.connectionProductUid
            )
        }
      } catch (error) {
        // No need to do anything. The price store has already been reset
        // and the error will be handled by the pricebook module.
        this.priceStore = false
      } finally {
        this.loadingPriceStore = false
      }
    },
    fetchDeals() {
      const companyUid = this.companyUid || ''
      const standardDeal = {
        entityUid: 'None',
        dealId: '',
        opportunityName: 'None',
      }

      sdk.instance
        .partnerVantage()
        .deals(companyUid)
        .then(res => {
          if (res.length) {
            this.deals = [
              standardDeal,
              ...res,
            ]
            // sort in ascending by deal id
            this.deals = this.deals.sort((a, b) => a.dealId.toUpperCase().localeCompare(b.dealId.toUpperCase()))
          } else {
            this.deals = [standardDeal]
            this.createConnectionForm.serviceObj.dealUid = 'None'
          }
        })
        .catch(e => captureSentryError(e))
    },
    /**
     * Save connection details to the services store
     */
    async addToCart() {
      this.saving = true

      let newService = {
        // Common fields
        productUid: this.connectionProductUid,
        productName: this.createConnectionForm.serviceObj.productName,
        provisioningStatus: G_PROVISIONING_DESIGN,
        rateLimit: this.createConnectionForm.serviceObj.rateLimit,
        shutdown: this.createConnectionForm.serviceObj.shutdown,
        // term: this.createConnectionForm.serviceObj.term, // Uncomment when term functionality has been extended to IXs
        dealUid: this.createConnectionForm.serviceObj.dealUid,
      }

      if (this.destinationTypeIsIx) {
        // Set up IX-specific fields
        newService = {
          ...newService,
          productType: this.G_PRODUCT_TYPE_IX,
          locationId: this.aEndPort.locationId,
          networkServiceType: this.bEndIxType.name,
          ixType: this.bEndIxType,
          publicGraph: this.createConnectionForm.serviceObj.publicGraph,
          asn: this.createConnectionForm.serviceObj.asn,
          vlan: this.createConnectionForm.serviceObj.vlan,
          costCentre: this.createConnectionForm.serviceObj.costCentre,
          macAddress: this.createConnectionForm.serviceObj.macAddress,
          password: this.createConnectionForm.serviceObj.password,
          ixPeerMacro: this.createConnectionForm.serviceObj.ixPeerMacro || null,
        }
      } else {
        // Set up VXC-specific fields
        newService = {
          ...newService,
          // Common VXC-specific fields
          aEnd: {
            locationId: this.aEndPort.locationId,
            ownerUid: this.aEndPort.companyUid,
            productUid: this.aEndPort.productUid,
            vlan: this.createConnectionForm.serviceObj.aEnd.vlan,
            partnerConfig: this.createConnectionForm.serviceObj.aEnd.partnerConfig,
          },
          bEnd: {
            locationId: this.bEndPort.locationId,
            ownerUid: this.bEndPort.companyUid,
            productUid: this.bEndPort.productUid,
            vlan: this.createConnectionForm.serviceObj.bEnd.vlan,
            partnerConfig: this.createConnectionForm.serviceObj.bEnd.partnerConfig,
            // Diversity zone is saved here for third-party service key connections
            // where this would otherwise be impossible to obtain after saving
            diversityZone: this.bEndDiversityZone,
          },
          productType: this.G_PRODUCT_TYPE_VXC,
          costCentre: this.createConnectionForm.serviceObj.costCentre,
          connectType: this.bEndPort.connectType,
          term: this.createConnectionForm.serviceObj.term,
        }

        // Strip out fixed bandwidths from B-End partner config
        if (newService.bEnd.partnerConfig.fixedBandwidths) {
          delete newService.bEnd.partnerConfig.fixedBandwidths
        }

        // Fixed port speed
        if (newService.bEnd.partnerConfig.fixedPortSpeed) {
          delete newService.bEnd.partnerConfig.fixedPortSpeed
        }

        // Strip out any previously entered B-End config fields if the user de-selects the BGP checkbox
        // for Megaport Internet (TRANSIT) connections
        if (this.destinationTypeIsTransit &&
          this.createConnectionForm.serviceObj.bEnd.partnerConfig.customer_asn &&
          !this.createConnectionForm.serviceObj.bEnd.partnerConfig._enableBgp) {
          delete newService.bEnd.partnerConfig.customer_asn
          delete newService.bEnd.partnerConfig.password
          delete newService.bEnd.partnerConfig.prefixes
        }

        // Strip out any string prefixes and convert the ASN to a number
        // This only applies to the ASN field for Megaport Internet connections
        if (this.destinationTypeIsTransit && newService.bEnd.partnerConfig.customer_asn) {
          newService.bEnd.partnerConfig.customer_asn = Number(newService.bEnd.partnerConfig.customer_asn.replace(/\D/g, ''))
        }

        // Azure peering
        if (this.usingAzurePeering) {
          newService.bEnd.innerVlan = Number.parseInt(this.bEndInnerVlan)
        }
      }

      // Clean up MCR records by removing interfaces with no defined IP addresses (except when auto-configuring)
      if (this.aEndIsMcr && !this.autoConfigured) {
        newService.aEnd.partnerConfig.interfaces = this.aEndMcrInterfaces
      }
      if (this.bEndIsMcr) {
        newService.bEnd.partnerConfig.interfaces = this.bEndMcrInterfaces
      }

      // A-End is MVE
      if (this.aEndIsMve) {
        if (this.aEndPort.provisioningStatus === G_PROVISIONING_DESIGN) newService.aEnd.vlan = 0
        newService.aEnd.innerVlan = this.aEndInnerVlan
        newService.aEnd.vNicIndex = this.aEndVNicIndex
      }

      // B-End is MVE
      if (this.bEndIsMve && !this.destinationTypeIsServiceKey) {
        if (this.bEndPort.provisioningStatus === G_PROVISIONING_DESIGN) newService.bEnd.vlan = 0
        newService.bEnd.innerVlan = this.bEndInnerVlan
        newService.bEnd.vNicIndex = this.bEndVNicIndex
      }

      try {
        if (this.fresh) {
          await this.addConnectionToService({ connection: newService, service: this.aEndPort })
        } else {
          this.updateExistingConnection(newService)
        }

        this.trackButtonClick('finish')

        // If we don't have the concept of configured services, then we skip the whole aside and
        // push to services part, and go directly to deployment.
        if (this.disabledFeatures.configuredServices) {
          this.$root.$emit('showOrderPanel')
        } else {
          this.$router.push(resolveServicesPage())
          this.$root.$emit('showActionAside')
        }
      } catch (e) {
        console.error(e)
        this.notifyBad({
          title: this.$t('connections.save'),
          message: this.$t('general.save-error', e),
        })
      } finally {
        this.saving = false
      }
    },
    async updateTermPrices() {
      this.termPrices = DEFAULT_TERM_PRICES

      if (
        this.destinationTypeIsIx ||
        !this.aEndProductUid ||
        !this.bEndProductUid ||
        !this.createConnectionForm.serviceObj.rateLimit
      ) return

      this.loadingTermPrices = true

      try {
        this.termPrices = await this.scopedPriceBook()
          .vxcTerms(
            this.aEndPort.locationId,
            this.bEndPort.locationId,
            this.createConnectionForm.serviceObj.rateLimit,
            this.aEndPort.productType,
            this.bEndPort.connectType,
            this.aEndPort.buyoutPort
          )
      } catch (error) {
        // No need to do anything. The default term prices will be shown
        // and the error will be handled by the pricebook module.
      } finally {
        this.loadingTermPrices = false
      }
    },

    // ----- Other methods -----
    initState() {
      // When we land, pull the portUid in for context as the A-End.
      if (!this.aEndProductUid) {
        this.createConnectionForm.serviceObj.aEnd.productUid = this.$route.params.productUid
      }

      if (this.aEndIsMve) {
        this.setMveOuterVlanForEnd('a')
        this.checkVNicExists('aEnd', this.aEndVNicIndex)
      }

      if (this.bEndIsMve) {
        this.checkVNicExists('bEnd', this.bEndVNicIndex)
      }

      // If there is a B-End type in the router string, set that too.
      const destinationType = this.$route.query.type?.toLowerCase()

      if (destinationType) {
        if (destinationType === 'ix') {
          this.createConnectionForm.destinationType = 'ix'
        }

        if (destinationType === 'transit') {
          // Port and MCR follow typical flow of needing to select the B-End
          this.createConnectionForm.destinationType = 'transit'
        }

        if (['default', 'vrouter', 'mve', 'mx'].includes(destinationType)) {
          this.createConnectionForm.destinationType = 'mx'
        }

        if (this.createConnectionForm.destinationType) {
          this.stepIndex = 1
        }

        if (this.$route.query.bEnd) {
          this.createConnectionForm.serviceObj.bEnd.productUid = this.$route.query.bEnd
        }
      }

      if (this.$route.query.cloud) {
        this.createConnectionForm.destinationType = 'cloud'

        if (this.createConnectionForm.destinationType) this.stepIndex = 1
      }

      if (this.bEndPort?.companyUid) {
        this.createConnectionForm.companySelectedUid = this.bEndPort.companyUid
      }

      this.showPricing && this.getPrice()
    },
    usingAzurePeeringChanged(value) {
      this.createConnectionForm.usingAzurePeering = value
      this.createConnectionForm.serviceObj.bEnd.innerVlan = null

      // Need to tell the form to clear the validation, and since the rules have changed,
      // also tell the component to clear the validation.
      this.$refs.innerVlan.clearValidate()

      // Since the validation rules have changed, need to wait for re-render before triggering validation.
      this.$nextTick(() => {
        if (this.aEndIsMve) {
          const vlanComponent = this.$refs['serviceObj.aEnd.innerVlan']

          vlanComponent?.triggerValidation()
        }
      })
    },
    typeSelected(destinationType) {
      const { vlan, vNicIndex } = this.createConnectionForm.serviceObj.bEnd

      let bEndInnerVlan = this.createConnectionForm.serviceObj.bEnd.innerVlan

      if (destinationType !== this.createConnectionForm.destinationType) {
        this.createConnectionForm.serviceObj.bEnd = {
          productUid: null,
          vlan: vlan || 0,
          innerVlan: bEndInnerVlan === null ? -1 : bEndInnerVlan || 0,
          partnerConfig: {
            complete: null,
            serviceKey: null,
          },
          vNicIndex: vNicIndex || 0,
          awsType: null,
        }
      }

      if (this.usingAzurePeering) {
        this.createConnectionForm.serviceObj.bEnd.innerVlan = bEndInnerVlan || null
      }

      this.createConnectionForm.destinationType = destinationType

      this.goToNextStep()
    },
    updateMcrConfig(configuration, end) {
      const { serviceObj } = this.createConnectionForm

      serviceObj[end] = {
        ...serviceObj[end],
        ...configuration,
      }

      this.createConnectionForm = {
        ...this.createConnectionForm,
        serviceObj: serviceObj,
      }
    },
    speedFix(val) {
      return convertSpeed(val) // Defined globally on window
    },
    displayAsActiveWhenDestinationTypeIs(destinationType) {
      return this.createConnectionForm.destinationType === destinationType ? 'is-active' : ''
    },
    minimumTermLabel(term) {
      if (term === 1) {
        return this.$t(this.isPartnerVantage ? 'partner-vantage.no-subscription' : 'port-term.no-minimum-term')
      } else {
        return this.$tc('general.count-months', term, { count: term })
      }
    },
    trackButtonClick(buttonName) {
      let base = `create-${this.destinationTypeIsIx ? 'ix' : 'vxc'}`

      if (!this.destinationTypeIsIx) {
        base += `.${this.createConnectionForm.destinationType}`
      }
      if (this.destinationTypeIsCloud && this.createConnectionForm.serviceObj.bEnd?.partnerConfig?.connectType) {
        base += `.${this.createConnectionForm.serviceObj.bEnd.partnerConfig.connectType.toLowerCase()}`
      }

      captureEvent(`${base}.${this.currentStep.id}.${buttonName}.click`, buttonName === 'next' ? {
        ...omit(this.createConnectionForm, ['destinationType', 'serviceObj']),
        ...omit(this.createConnectionForm.serviceObj, ['aEnd', 'bEnd', 'password', 'provisioningStatus']),
        aEnd: omit(this.createConnectionForm.serviceObj.aEnd, ['partnerConfig']),
        ...this.destinationTypeIsIx
          ? { ixType: this.createConnectionForm.serviceObj.bEnd?.ixType }
          : { bEnd: omit(this.createConnectionForm.serviceObj.bEnd, ['partnerConfig']) },
      } : undefined)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding-top: 1rem;
  background-color: var(--mp-sub-header-background-color);
  border-bottom: 1px solid var(--card-border-color);
}
.steps {
  padding: 1rem;
}

.content-panel {
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 3rem;
  margin: auto;
  width: fit-content;
  max-width: 1500px;
  min-width: 300px;
}

.disabled svg.icon {
  color: var(--font-color-disabled-base);
}

svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5em;
  height: 2.5em;
  fill: currentColor;
  &.transfer {
    margin: 1.5rem;
  }
}

.price-box {
  margin: auto;
  margin-bottom: 1rem;
  width: fit-content;
  height: 2rem;
  color: var(--color-text-regular);
  font-size: 1.4rem;
  font-weight: 300;
}

.dest-type {
  margin: 0.3rem;
  padding: 0.5rem;
  width: 150px;
  height: 150px;
  svg {
    display: inline-block;
    width: 5em;
    height: 5em;
    fill: currentColor;
  }
  .dest-type-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: 0.75rem;
    white-space: normal;
    word-break: normal;
    height: 30px;
    width: 100%;
    color: var(--color-text-primary);
  }
  &:hover .dest-type-text {
    color: var(--color-primary);
  }
  &.el-button {
    margin: 0.3rem;
  }
}

.dest-type[disabled] {
  svg,
  .dest-type-text {
    color: var(--font-color-disabled-base);
  }
}

.dest-type-content {
  min-width: 140px;
  text-align: center;
  color: var(--link-color);
}
.dest-type.is-active {
  background-color: var(--color-primary);
  &:hover {
    background-color: var(--color-primary-light-9);
    .dest-type-text {
      color: var(--color-primary);
    }
    svg {
      color: var(--color-primary);
      fill: currentColor;
    }
  }
  .dest-type-text {
    color: var(--color-white);
  }
}
.is-active .dest-type-content {
  color: var(--color-white);
}

.total {
  margin-top: 2rem;
  padding: 1rem;
  padding-top: 2rem;
  white-space: normal;
  word-break: break-all;
  border-block: 1px solid var(--card-border-color);
  color: var(--color-black);
  & .el-col:first-of-type {
    font-weight: 500;
  }
}

.error-message {
  color: var(--color-danger);
  text-align: center;
}

.expand-arrow {
  transition: transform 0.3s;
  &.active {
    transform: rotate(180deg);
  }
  &:focus {
    outline: none;
  }
}

.summary-section-head {
  text-align: center;
  background: var(--button-default-background-color);
  border: var(--border-base);
  border-radius: var(--button-border-radius);
  border-color: var(--button-default-border-color);
  color: var(--button-default-font-color);
  margin: 1rem;
  transition: 0.1s;
  cursor: pointer;
  &:hover {
    color: var(--color-primary);
    border-color: var(--color-primary-light-7);
    background-color: var(--color-primary-light-9);
  }
  &:focus {
    outline: none;
  }
}

.azure-settings-group {
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  padding: 1rem 1.4rem 0 1rem;
  margin-bottom: 1rem;
  color: var(--color-text-primary);

  & .el-input {
    width: 100% !important;
  }
}

.width-120px {
  width: 120px;
}

.max-width-700px {
  max-width: 700px;
}

.ml-200px {
  margin-left: 200px;
}

.mt-20px {
  margin-top: 20px;
}

.configure-connection-container {
  max-width: 700px;

  & .el-input {
    width: 450px;
  }
}

::v-deep .el-step__title {
  margin-inline: 2px;
}
</style>

<style lang="scss">
button.el-button.el-button--primary.is-plain.step-button {
  &:focus {
    background-color: var(--color-primary-light-9);
    color: var(--color-primary);
    border-color: var(--color-primary-light-6);
    outline: none;
  }
}
</style>
