<template>
  <div class="content-column">
    <h4 class="text-align-center mt-1 mb-2">
      {{ $t('connections.ix-details') }}
    </h4>

    <!-- Preferred VLAN -->
    <input-vlan ref="serviceObj.vlan"
      v-model="service.vlan"
      prop="serviceObj.vlan"
      :label-width="labelWidth"
      :service="value"
      end="a" />

    <!-- ASN -->
    <el-form-item prop="serviceObj.asn"
      :label-width="labelWidth">
      <template #label>
        {{ $t('connections.asn') }}
        <el-tooltip placement="top"
          :content="$t('connections.asn-full')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input v-model="service.asn"
        type="text"
        data-testid="ix-asn"
        data-demo="6533"
        name="asn" />
    </el-form-item>

    <!-- MAC Address -->
    <mac-address v-model="service.macAddress"
      validation-key="serviceObj.macAddress"
      :label-width="labelWidth" />

    <!-- BGP Password -->
    <el-form-item prop="serviceObj.password"
      :label-width="labelWidth">
      <template #label>
        {{ $t('connections.bgp-password') }}
        <el-tooltip placement="top"
          :content="$t('ix.bgp-password-tooltip')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input v-model="service.password"
        :type="showPassword ? 'text' : 'password'"
        data-testid="ix-bgp-password"
        data-demo="new-password"
        name="BGPPassword">
        <span slot="suffix"
          class="cursor-pointer">
          <i :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"
            class="fa mr-1"
            @click="showPassword = !showPassword" />
        </span>
      </el-input>
    </el-form-item>

    <!-- Graph Visibility -->
    <el-form-item prop="serviceObj.publicGraph"
      :label-width="labelWidth"
      :label="$t('connections.graph-visibility')">
      <el-radio-group v-model="service.publicGraph"
        name="publicGraph">
        <el-radio-button :label="true"
          class="inverse-padding">
          <el-tooltip placement="top"
            :content="$t('ix.looking-glass-graphs')"
            :open-delay="500">
            <span>{{ $t('general.public') }}</span>
          </el-tooltip>
        </el-radio-button>
        <el-radio-button :label="false"
          class="inverse-padding">
          <el-tooltip placement="top"
            :content="$t('ix.no-looking-glass-graphs')"
            :open-delay="500">
            <span>{{ $t('general.private') }}</span>
          </el-tooltip>
        </el-radio-button>
      </el-radio-group>
    </el-form-item>

    <!-- Peer Macro -->
    <el-form-item v-if="value.bEnd.ixType.ecix"
      prop="serviceObj.ixPeerMacro"
      class="long-error-de long-error-es long-error-fr long-error-ja"
      :label="$t('connections.peer-macro')"
      :label-width="labelWidth">
      <el-input v-model="service.ixPeerMacro"
        type="text"
        name="ixPeerMacro" />
    </el-form-item>
  </div>
</template>

<script>
import { deepClone } from '@/helpers.js'
import InputVlanComponent from '@/components/InputVlan.vue'
import MacAddressComponent from '@/components/ui-components/MacAddress.vue'

export default {
  name: 'IxConfig',

  components: {
    'input-vlan': InputVlanComponent,
    'mac-address': MacAddressComponent,
  },

  inject: ['disabledFeatures'],

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      service: {
        publicGraph: true,
        asn: '',
        vlan: 0,
        costCentre: null,
        macAddress: '',
        password: '',
        ixPeerMacro: null,
      },
      showPassword: false,
      labelWidth: '180px',
    }
  },

  computed: {
    isComplete() {
      return !!(this.service && this.service.publicGraph !== undefined && this.service.asn && this.service.macAddress)
    },
  },

  watch: {
    service: {
      handler() {
        this.emitUpdatedService()
      },
      deep: true,
    },
  },

  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        if (typeof this.service[key] !== 'undefined' && key !== 'complete') {
          this.service[key] = this.value[key]
        }
      })
      this.emitUpdatedService()
    }
  },

  methods: {
    emitUpdatedService() {
      const updatedService = deepClone(this.value)
      updatedService.bEnd.partnerConfig.complete = this.isComplete
      Object.keys(this.service).forEach(key => {
        updatedService[key] = this.service[key]
      })
      this.$emit('input', updatedService)
    },
  },
}
</script>

<style lang="scss" scoped>
.content-column {
  padding: 1rem;
  box-sizing: border-box;
  width: min-content;
  min-width: 550px;
}
</style>
